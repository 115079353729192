import { useEffect, useState } from "react"
import { CountryDDL } from "../../../Components/DDL/CountryDDL/CountryDDL"
import { RegExEmail, RegExNumbersOnly, RegExOnlyText } from "../../../Helper/Regx/Regx"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../Helper/Context"
import { AstricSign } from "../../../Helper/AstricSign/AstricSign"
import { CountryCodeDDLAPI } from "../../../Redux/DDLSlice"
import { PostQueryAPI } from "../../../Redux/PartnerRegistrationSlice/PartnerRegistrationSlice"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const QueryForm = () => {

    const dispatch = useDispatch()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [CountryCodeID, setCountryCodeID] = useState('')
    const [IsDisable, setIsDisable] = useState(true)
    const [PostMsg, setPostMsg] = useState('')
    const [loading, setloading] = useState(false)


    const [FormData, setFormData] = useState({
        FullName: '',
        Address: '',
        Email: '',
        ContactNumber: '',
        Query: ''
    })

    const [CountryTypeDDL, setCountryTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData(pre => ({
            ...pre, [name]: value
        }))
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        onChange(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const [IsValidEmail, setIsValidEmail] = useState(false)
    const handleCheckEmail = (e) => {
        onChange(e)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumber = (e) => {
        onChange(e)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }

    useEffect(() => {
        const data = { UserID, token, CountryTypeDDL, setCountryCodeID }
        dispatch(CountryCodeDDLAPI({ data }))
    }, [CountryTypeDDL])

    const { DDLData } = useSelector(state => state.CountryCodeDDLData)

    const handlePostRegistration = (data) => {
        setPostMsg(data)
        setTimeout(() => {
            setloading(false)
            // handleShowRegistration()
        }, 2000);
    }

    const handleClear = () => {
        setCountryTypeDDL({
            ...CountryTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
        setFormData({
            FullName: '',
            Address: '',
            Email: '',
            ContactNumber: '',
            Query: ''
        })
    }

    const handleSubmit = () => {
        setloading(true)
        const data = {
            M_CountryNameID: CountryCodeID ? CountryCodeID : '0',
            Full_Name: FormData.FullName,
            Email_ID: FormData.Email,
            MobileNo: FormData.ContactNumber,
            Address: FormData.Address,
            Query: FormData.Query,
            M_UserID: '0',
            handlePostRegistration,
            setPostMsg,
            handleClear
        }
        dispatch(PostQueryAPI({ data }))
    }

    const { isRegistrationLoading } = useSelector(state => state.PostQueryData)


    const IsValidate = () => {
        if (
            IsValidText || IsValidEmail || IsValidNumber ||
            FormData.Email == '' || CountryTypeDDL.ID == '0' || FormData.FullName == '' ||
            FormData.ContactNumber == '' || FormData.Address == '' || FormData.Query == ''
        ) {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }
    }

    useEffect(() => {
        IsValidate()
    }, [FormData, CountryCodeID])

    return (
        <>
            <div className="row">
                <div className="col-12 ">


                    <div className="offcanvas offcanvas-bottom rounded rounded-3 me-3 mb-3" tabindex="-1" id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel">
                        <div className="offcanvas-header lightgreenbg">
                            <h5 className="offcanvas-title" id="offcanvasBottomLabel">

                                <LazyLoadImage effect="blur" src="https://ik.imagekit.io/gplanet/Gplanet/img/GCP_logo.png?updatedAt=1701510152620" alt="..." width="40px" style={{ background: 'white', borderRadius: '100px', padding: '15px 5px' }} />

                                <span className="ps-3 text-white  fs-6" >Global Green Credit Initiative
                                </span>
                            </h5>

                            {/* <i className="  "  style={{ color: 'white' }}></i> */}
                            <i className="fa-solid fa-xmark fs-4 text-white cursor-pointer " data-bs-dismiss="offcanvas" onClick={() => { handleClear(); setPostMsg('') }}></i>
                        </div>
                        <div className="offcanvas-body small">
                            <div className="col-12 px-4 py-3">
                                <form>

                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Name<AstricSign /></label>
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="FullName"
                                                value={FormData.FullName}
                                                // onChange={onChange}
                                                onChange={(e) => handleCheckText(e)}
                                            />
                                            {
                                                IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                            }
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Country<AstricSign /></label>
                                        <CountryDDL
                                            CountryTypeDDL={CountryTypeDDL}
                                            setCountryTypeDDL={setCountryTypeDDL}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Mobile Number<AstricSign /></label>
                                        <div className="col-12">
                                            <div className="input-group ">
                                                <span className="input-group-text">

                                                    <select className="" style={{ border: 'none', backgroundColor: '#E9ECEF' }}
                                                        onChange={(e) => {
                                                            setCountryCodeID(e.target.value)
                                                        }}
                                                    >
                                                        {
                                                            DDLData && DDLData.length > 0 && DDLData.map((item) => (
                                                                <option key={item.m_CountryID} value={item.m_CountryID} selected>{item.mobileCountryCode}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </span>

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="ContactNumber"
                                                    value={FormData.ContactNumber}
                                                    // onChange={onChange} 
                                                    onChange={(e) => handleCheckNumber(e)}
                                                />
                                                {
                                                    IsValidNumber && <text className="RegxValidation">Please enter Numeric characters only</text>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Email ID<AstricSign /></label>
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Email"
                                                value={FormData.Email}
                                                // onChange={onChange} 
                                                onChange={(e) => handleCheckEmail(e)}
                                            />
                                            {
                                                IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                            }
                                        </div>
                                    </div>


                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Address / Location<AstricSign /></label>
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Address"
                                                value={FormData.Address}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>


                                    <div className="mb-2">
                                        <label for="exampleInputEmail1" className="form-label">Query<AstricSign /></label>
                                        <div className="col-12">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="Query"
                                                rows="3"
                                                placeholder="Leave a comment here"
                                                value={FormData.Query}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>

                                    <p className="py-5">
                                        <div className="col-12 mt-0">
                                            <text style={{ color: '#006939', textAlign: 'center' }}>
                                                {PostMsg}
                                            </text>
                                        </div>

                                        <div className="col-12">
                                            <button type="button" className="btn float-end lightgreenbg text-white px-4"
                                                disabled={IsDisable || isRegistrationLoading || loading}
                                                onClick={handleSubmit}
                                            >
                                                {(isRegistrationLoading || loading) && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {(!isRegistrationLoading || !loading) && <span>Submit</span>}
                                            </button>
                                        </div>
                                    </p>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" id="offcanvas_button">
                <div className="col-12">
                    <button className="btn lightgreenbg back-to-top2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                        style={{ borderRadius: '100px', padding: '8px 8px' }} onClick={() => { handleClear(); setPostMsg('') }}>
                        <div style={{ background: 'white', borderRadius: '100px', padding: '12px 5px' }}>
                            <LazyLoadImage effect="blur" src="https://ik.imagekit.io/gplanet/Gplanet/img/GCP_logo.png?updatedAt=1701510152620" alt="..." width="40px" />
                        </div>

                    </button>


                    <img src="assets/img/ggci-01.png" class="image_circle_logo" alt="" />

                </div>
            </div>
        </>
    )
}