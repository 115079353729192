
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";



// Get Table Data
export const EventTableDataAPI = createAsyncThunk(" EventTableData", async ({ data }) => {
    const {
        M_EventID,
        M_EventTypeID,
        EventDate,
        Flag,
        M_UserID,
        token,
        TOP,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Event_Select?M_EventID=${M_EventID ? M_EventID : '0'}&M_EventTypeID=${M_EventTypeID ? M_EventTypeID : '0'}&EventDate=${EventDate ? EventDate : ''}&M_UserID=${M_UserID}&Flag=${Flag}&TOP=${TOP}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const EventTableDataSlice = createSlice({
    name: " EventTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EventTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EventTableDataReducer = EventTableDataSlice.reducer

// InsertUpdate Table Data

export const EventPostAPI = createAsyncThunk("EventPost", async ({ data }) => {
    const {
        M_EventID,
        M_EventTypeID,
        M_EventSubTypeID,
        M_PartnerRegistrationID,
        EventStartDateTime,
        EventEndDateTime,
        EventSubject,
        ShortDescription,
        EventPhoto,
        EventDescription,
        EventTime,
        location,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleAddCloseClick,
        setisLoading,
        PopUpFlag

    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    if (PopUpFlag == "Update") {
        formdata.append("M_EventID", M_EventID);
    }
    formdata.append("M_EventTypeID", M_EventTypeID);
    formdata.append("M_EventSubTypeID", M_EventSubTypeID);
    formdata.append("M_PartnerRegistrationID", M_PartnerRegistrationID);
    formdata.append("EventStartDateTime", EventStartDateTime);
    formdata.append("EventEndDateTime", EventEndDateTime);
    formdata.append("EventSubject", EventSubject);
    formdata.append("EventDescription", EventDescription);
    formdata.append("ShortDescription", ShortDescription);
    formdata.append("EventPhoto", EventPhoto);
    formdata.append("Location", location);
    formdata.append("EventTime", EventTime);
    formdata.append("Flag", Flag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: PopUpFlag == 'Update' ? 'PATCH' : 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    setisLoading(true)

    let url
    if (PopUpFlag == "Update") {
        url = `${BaseUrl}/Master/Post_M_Event_Update`
    } else {
        url = `${BaseUrl}/Master/Post_M_Event_Insert`
    }

    fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                toastSuccesss(result.message)
                return result
            } else {
                toastErrorr(result.message)
            }
            setisLoading(false)
            return result
        })
})

const EventPostSlice = createSlice({
    name: "EventPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(EventPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EventPostReducer = EventPostSlice.reducer


// Delete Table Data

export const EventMasterDeleteAPI = createAsyncThunk("EventMasterDelete", async ({ data }) => {

    const {
        M_EventID,
        M_UserID,
        handleIsPost,
        token,
        handleAddCloseClick,
        setIsDeleteLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_EventID", M_EventID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Event_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsDeleteLoading(false)
                handleIsPost()
                toastSuccesss(result.message)
                handleAddCloseClick()
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            setIsDeleteLoading(false)
            return result

        })
})

const EventMasterDeleteSlice = createSlice({
    name: "EventMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(EventMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(EventMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EventMasterDeleteReducer = EventMasterDeleteSlice.reducer



// Delete Table Data

export const EventGallaryDeleteAPI = createAsyncThunk("EventGallaryDelete", async ({ data }) => {

    const {
        M_Event_Wise_PhotoID,
        Flag,
        M_UserID,
        handleIsPost,
        token,
        handleAddCloseClick,
        setIsDeleteLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_Event_Wise_PhotoID", M_Event_Wise_PhotoID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    let url
    if (Flag == 'Photo') {
        url = `${BaseUrl}/Master/Post_M_Event_Wise_Photo_Delete`
    } else {
        url = `${BaseUrl}/Master/Post_M_Event_Wise_Video_Delete`
    }

    fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsDeleteLoading(false)
                handleIsPost()
                toastSuccesss(result.message)
                handleAddCloseClick()
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            setIsDeleteLoading(false)
            return result

        })
})

const EventGallaryDeleteSlice = createSlice({
    name: "EventGallaryDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventGallaryDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(EventGallaryDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(EventGallaryDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EventGallaryDeleteReducer = EventGallaryDeleteSlice.reducer


export const PublishEventAPI = createAsyncThunk("PublishEvent", async ({ data }) => {
    const {
        M_EventID,
        ActionID,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_EventID", M_EventID);
    formdata.append("ActionID", ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    setloading(true)
    fetch(`${BaseUrl}/Master/Admin_M_KnowledgeRepository_CaseStudies_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(false)
            return result

        })
})

const PublishEventSlice = createSlice({
    name: "PublishEvent",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PublishEventAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PublishEventAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PublishEventAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PublishEventReducer = PublishEventSlice.reducer




export const IndividualPublishPhotoVideoAPI = createAsyncThunk("IndividualPublishPhotoVideo", async ({ data }) => {
    const {
        M_Event_Wise_PhotoVideoID,
        ActionFlag,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_Event_Wise_PhotoVideoID", M_Event_Wise_PhotoVideoID);
    formdata.append("ActionFlag", ActionFlag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    // setloading(true)
    fetch(`${BaseUrl}/Master/Patch_M_Event_Wise_PhotoVideo_Publish`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            // setloading(false)
            return result

        })
})

const IndividualPublishPhotoVideoSlice = createSlice({
    name: "IndividualPublishPhotoVideo",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(IndividualPublishPhotoVideoAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(IndividualPublishPhotoVideoAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(IndividualPublishPhotoVideoAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const IndividualPublishPhotoVideoReducer = IndividualPublishPhotoVideoSlice.reducer


