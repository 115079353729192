import { useNavigate, useSearchParams } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from "../../../../../Helper/NoData/NoData";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../../Helper/Context";
import { PhotovideoEventTableDataAPI } from "../../../../../Redux/MultimediaSlice/MultimediaSlice";
import { useEffect, useState } from "react";
import { LogoLoader } from "../../../../../Helper/LogoLoader";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { EventData } from "../../../../../Helper/DummyData";

export const HomePastEvent = () => {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    const [PastEvent, setPastEvent] = useState('')

    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    useEffect(() => {
        setPastEvent([])
        const data = {
            M_EventID: '0',
            Flag: id == 1 ? 'Workshop_PastEvent' : 'Webinar_PastEvent',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
            name: 'PastEvent',
            setPastEvent: setPastEvent
        }
        dispatch(PhotovideoEventTableDataAPI({ data }))
    }, [id])

    useEffect(() => {
        console.log("Fetched PastEvent Data: ", PastEvent);  // Debugging the data
    }, [PastEvent]);

    const { isLoading, tableData } = useSelector(state => state.PhotovideoEventTableData)
    const options = {
        500: {
            items: 4
        }
    }

    return (
        <>
            {isLoading && <LogoLoader />}
            {/* <div className="row p-4 p-md-5">
                <h5 className="py-3 text-white lightgreenbg ps-4 border rounded " style={{ fontWeight: 'normal' }}>Past Events</h5> */}

            <div className="row upcomming px-lg-5 pb-lg-2 p-3  pt-5">
                <div className="row d-flex align-items-center text-white lightgreenbg border rounded ps-4 py-2">
                    <div className="col-12 col-lg-6 " >
                        <h5 className="cpitalize text-white" style={{ fontWeight: 'normal' }}>Past Events </h5>
                    </div>
                </div>
            </div>

            <div className="row mx-5 mb-5 event_height">
                <>
                    {
                        PastEvent && PastEvent.length > 0 ? PastEvent.map(i => {
                            return (
                                <div className="col-12 col-md-4 col-lg-3 px-lg-3 pt-lg-4" >

                                    <div className="shadow_media rounded border  gray cursor-pointer bg-white" >

                                        <LazyLoadImage
                                            effect='blur'
                                            src={i?.eventPhoto != null ? i?.eventPhoto : "assets/img/event/no_img-01.jpg"}
                                            alt="About"
                                            width='100%'
                                            height='200px'
                                            onClick={() => window.open(i.eventPhoto)}
                                            onError={(e) => {
                                                e.target.src = "assets/img/404image.jpeg"; 
                                            }}
                                        />

                                        <div className="  px-3 mt-3 text-start fw-bold lightgreencolor" style={{ fontSize: '14px', height: '5vh' }} >
                                            {i?.eventSubject}
                                        </div>
                                        <div className=" border-success  px-3" style={{ fontSize: '13px', height: '5vh' }}>
                                            <span className="text-dark" ><i class="fa-solid fa-location-dot text-danger me-1"></i> {i?.location}</span>
                                        </div>
                                    </div>


                                </div>
                            )
                        })
                            :

                            <div class="row">
                                <div className='col-12 col-md-4 mx-auto text-center '>
                                    <NoData />
                                </div>
                            </div>
                    }
                </>
            </div>

            {/* {
                PastEvent && PastEvent.length > 0 ? PastEvent.map(i => {
                    return (
                        <div className=" col-12 col-md-4 px-lg-3  pt-lg-4" >

                            <div className="shadow_media rounded border  gray cursor-pointer bg-white" >

                                <LazyLoadImage effect='blur' src={i?.eventPhoto != null ? i?.eventPhoto : "assets/img/event/no_img-01.jpg"} alt="About" width='100%' height='190px'
                                    onClick={() => window.open(i.eventPhoto)}
                                />

                                <div className="  px-3 mt-3 text-start fw-bold lightgreencolor" style={{ fontSize: '14px', height: '5vh' }} >
                                    {i?.eventSubject}
                                </div>
                                <div className=" border-success  px-3" style={{ fontSize: '13px', height: '5vh' }}>
                                    <span className="text-dark" ><i class="fa-solid fa-location-dot text-danger me-1"></i> {i?.location}</span>
                                </div>
                            </div>


                        </div>
                    )
                })
                    :

                    <div class="row">
                        <div className='col-12 col-md-4 mx-auto text-center '>
                            <NoData />
                        </div>
                    </div>
            } */}

        </>
    )
}