import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import ReportDocument from '../ReportDocument/ReportDocument'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CaseStudyDataAPI } from '../../../../../Redux/CaseStudySlice/CaseStudySlice'
import moment from 'moment';
import { useAuthState } from '../../../../../Helper/Context'
import { LoginPageModal } from '../../../Event/Modal/LoginPageModal'
import { getWindowDimensions } from '../../../../../Helper/getWindowDimensions'

const CaseStudy = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const data = {
            CaseDate: '',
            Flag: 'WEB',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
        }

        dispatch(CaseStudyDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.CaseStudyData)

    const [ShowLoginPage, setShowLoginPage] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [showMessage, setShowMessage] = useState(false);

    const handleShowLoginPagePage = () => {
        setShowLoginPage(!ShowLoginPage)
    }

    const handlePost = () => {
        setIsPost(!IsPost)
        setShowLoginPage(false)
    }
    const Session = sessionStorage.getItem('GPlaNET')

    const handleReadMoreClick = (reportDoc) => {
        const url = `${reportDoc}#toolbar=0`
        if (Session == null) {
            setShowLoginPage(true);
            // setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
        } else {
            if (reportDoc) {
                // handlePdfShow(reportDoc)
                // window.open(reportDoc, "_self", 'width="80%",height="80%"');
                window.open(reportDoc, '_blank', `top=50,width=${windowDimensions.width - 10},height=${windowDimensions.height - 0}`)
            }
        }
    };

    const handleViewClick = (reportDoc) => {
        const url = `${reportDoc}#toolbar=0`
        window.open(url, '_blank', `top=50,width=${windowDimensions.width - 10},height=${windowDimensions.height - 0}`)
    }


    return (
        <>
            <Header active='Knowledge' />

            <PagesHeroSection
                Heading='Case Study'
                BreadScrum1='Home'
                BreadScrum2='Knowledge Repository'
            />

            {
                Session == null &&
                <div className="alert alert-warning mt-3 text-center mb-2">
                    <text style={{ fontSize: '14px' }}>
                        You need to log in to Download Case Study Document. &nbsp;
                        <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => setShowLoginPage(true)}
                        >
                            Click here to Login...</span>
                    </text>
                </div>
            }

            <div className="row px-3 px-lg-5 pb-4">

                {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (
                            <div class="col-12 col-md-6 px-4 mt-1 px-1 px-lg-4">
                                {/* <div class="pt-4">
                                    <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '25px' }} className="py-2" />
                                    <span class="text-dark ps-2"> {i?.caseStudiesSubject}</span> */}

                                <div class="pt-4 row py-3 font_size fw-lighter border-bottom pb-2">

                                    <div className='col-9'>
                                        <div>
                                            <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '18px' }} className="py-2" />
                                            <span class="text-dark ps-2"> {i?.caseStudiesSubject}</span>
                                        </div>

                                        <div>
                                            <LazyLoadImage effect='blur' src="assets/img/calendar.png" alt="About" style={{ width: '11px' }} className="me-2" />
                                            <span style={{ fontSize: '13px' }}>{i.caseDate ? moment(i.caseDate).format('MMMM DD YYYY') : ''}</span>
                                        </div>
                                    </div>
                                    {
                                        Session == null ?
                                            <div className='col-3 mt-3 pt-1'>
                                                <span
                                                    onClick={() => handleViewClick(i?.reportDoc)}
                                                    className="float-end text-success fs-6 cursor-pointer"
                                                >
                                                    View
                                                    <i className="fa-solid fa-arrow-right ps-2">&nbsp;</i>
                                                </span>
                                            </div>
                                            :
                                            <div className='col-3 mt-3 pt-1'>
                                                <span
                                                    onClick={() => handleReadMoreClick(i?.reportDoc)}
                                                    className="float-end text-success fs-6 cursor-pointer"
                                                >
                                                    Download
                                                    <i className="fa-solid fa-download ps-2">&nbsp;</i>
                                                </span>
                                            </div>
                                    }

                                </div>
                            </div>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                }

            </div >

            <Footer />
            {
                ShowLoginPage && <LoginPageModal
                    handleShowLoginPagePage={handleShowLoginPagePage}
                    handlePost={handlePost}
                />
            }
        </>
    )
}

export default CaseStudy