import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'


const Sidebar = (props) => {

    const { active } = props

    const navigate = useNavigate()
    const [params] = useSearchParams();

    const username = params.get('username');
    // function toggle() {
    //     let side = document.getElementById("sidebar")
    //     side.classList.toggle("active")
    // }

    const onMouseOver = () => {
        let side = document.getElementById("sidebar")
        side.classList.remove("active")
    }

    const onMouseOut = () => {
        let side = document.getElementById("sidebar")
        side.classList.add("active")
    }


    return (
        <>
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 lightgreenbg active" id='sidebar'
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <div
                    className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    {/* <a href="/"
                        className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-5 d-none d-sm-inline">Menu</span>
                    </a> */}

                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start"
                        id="menu">                    
                        {
                            username == 'admin' ?
                                <>
                                    <li className={`nav-item ${active == 'PartnerApproval' ? 'activebg' : ''} `} >
                                        <a onClick={() => navigate(`/admin?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className={`fa-solid fa-house-chimney ${active == 'PartnerApproval' ? 'text_black' : ''}`}></i>
                                            <span className={`ms-1 hideLabel ${active == 'PartnerApproval' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>User Approval</span>
                                            {/* <i className='fa-solid fa-house-chimney text_black'></i>
                                            <span className='ms-1 hideLabel text_black' style={{ fontSize: '14px' }}>Partner Approval</span> */}
                                        </a>
                                    </li>

                                    {/* <li className={`nav-item ${active == 'PledgeParticipant' ? 'activebg' : ''}`} >
                                        <a onClick={() => navigate(`/pledgeParticipant?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className={`fa-solid fa-square-poll-horizontal ${active == 'PledgeParticipant' ? 'text_black' : ''}`}></i>
                                            <span className={`ms-1 hideLabel ${active == 'PledgeParticipant' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Pledge
                                                Participant</span>
                                        </a>
                                    </li> */}

                                    {/* <li className="nav-item">
                                        <a onClick={() => navigate(`/pledgeParticipant?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                            <i className="fa-solid fa-square-poll-horizontal"></i>
                                            <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Pledge
                                                Participant</span>
                                        </a>
                                    </li> */}



                                    {/* <li className="nav-item">
                                        <a onClick={() => navigate(`/queryScreen?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                            <i className="fa-solid fa-table-list"></i>
                                            <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Received Query
                                            </span>
                                        </a>
                                    </li> */}

                                    <li className={`nav-item ${active == 'ReceivedQuery' ? 'activebg' : ''}`} >
                                        <a onClick={() => navigate(`/queryScreen?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className={`fa-solid fa-table-list ${active == 'ReceivedQuery' ? 'text_black' : ''}`}></i>
                                            <span className={`ms-1 hideLabel ${active == 'ReceivedQuery' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Received Query</span>
                                        </a>
                                    </li>

                                </>
                                :
                                <></>
                        }

                        {/* <li className="nav-item">
                            <a onClick={() => navigate(`/knowledgeRepositoryReport?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                <i className="fa-solid fa-file"></i>
                                <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Partner Report</span>
                            </a>
                        </li> */}

                        <li className={`nav-item ${active == 'PartnerReport' ? 'activebg' : ''}`} >
                            <a onClick={() => navigate(`/knowledgeRepositoryReport?username=${username}&IndicatorName=9`)} className="nav-link align-middle text-white px-0 d-flex"
                                style={{ cursor: 'pointer' }}
                            >
                                <i className={`fa-solid fa-file ${active == 'PartnerReport' ? 'text_black' : ''}`}></i>
                                <span className={`ms-1 hideLabel ${active == 'PartnerReport' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}> Report</span>
                            </a>
                        </li>

                        {/* <li className="nav-item">
                            <a onClick={() => navigate(`/knowledgeRepositoryCaseStudy?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                <i className="fa-regular fa-file-lines"></i>
                                <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Partner Case Study</span>
                            </a>
                        </li> */}

                        <li className={`nav-item ${active == 'PartnerCaseStudy' ? 'activebg' : ''}`} >
                            <a onClick={() => navigate(`/knowledgeRepositoryCaseStudy?username=${username}&IndicatorName=10`)} className="nav-link align-middle text-white px-0 d-flex"
                                style={{ cursor: 'pointer' }}
                            >
                                <i className={`fa-solid fa-file-lines ${active == 'PartnerCaseStudy' ? 'text_black' : ''}`}></i>
                                <span className={`ms-1 hideLabel ${active == 'PartnerCaseStudy' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}> Case Study</span>
                            </a>
                        </li>


                        {/* <li className="nav-item">
                            <a onClick={() => navigate(`/event?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                <i className="fa-solid fa-camera-retro"></i>
                                <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Event</span>
                            </a>
                        </li> */}
                        {
                            username == 'admin' &&
                            <li className={`nav-item ${active == 'Event' ? 'activebg' : ''}`} >
                                <a onClick={() => navigate(`/event?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className={`fa-solid fa-business-time ${active == 'Event' ? 'text_black' : ''}`}></i>
                                    <span className={`ms-1 hideLabel ${active == 'Event' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Event Master</span>
                                </a>
                            </li>
                        }


                        {/* <li className="nav-item">
                            <a onClick={() => navigate(`/eventgallery?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                <i className="fa-solid fa-camera-retro"></i>
                                <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>Event Gallery</span>
                            </a>
                        </li> */}

                        <li className={`nav-item ${active == 'EventGallery' ? 'activebg' : ''}`} >
                            <a onClick={() => navigate(`/eventgallery?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                style={{ cursor: 'pointer' }}
                            >
                                <i className={`fa-solid fa-camera-retro ${active == 'EventGallery' ? 'text_black' : ''}`}></i>
                                <span className={`ms-1 hideLabel ${active == 'EventGallery' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Event Gallery</span>
                            </a>
                        </li>

                        {
                            username == 'admin' ?

                                // <li className="nav-item">
                                //     <a onClick={() => navigate(`/newsLetterAdmin?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                //         <i className="fa-regular fa-envelope"></i>
                                //         <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>News Letter
                                //         </span>
                                //     </a>
                                // </li>
                                <li className={`nav-item ${active == 'NewsLetter' ? 'activebg' : ''}`} >
                                    <a onClick={() => navigate(`/newsLetterAdmin?username=${username}&IndicatorName=11`)} className="nav-link align-middle text-white px-0 d-flex"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i className={`fa-regular fa-envelope ${active == 'NewsLetter' ? 'text_black' : ''}`}></i>
                                        <span className={`ms-1 hideLabel ${active == 'NewsLetter' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Press Release</span>
                                    </a>
                                </li>

                                :
                                <></>
                        }

                        {
                            username == 'admin' ?

                                <li className={`nav-item ${active == 'DiscussionForumForm' ? 'activebg' : ''}`} >
                                    <a onClick={() => navigate(`/DiscussionForumForm?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i className={`fa-regular fa-comments ${active == 'DiscussionForumForm' ? 'text_black' : ''}`}></i>
                                        <span className={`ms-1 hideLabel ${active == 'DiscussionForumForm' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>Discussion Forum</span>
                                    </a>
                                </li>

                                :
                                <></>
                        }

                        {/* <li className="nav-item">
                                <a onClick={() => navigate(`/newsAdmin?username=${username}`)} style={{ cursor: 'pointer' }} className="nav-link align-middle text-white px-0 d-flex">
                                    <i className="fa-regular fa-newspaper"></i>
                                    <span className="ms-1 hideLabel" style={{ fontSize: '14px' }}>News </span>
                                </a>
                            </li> */}

                        {/* <li className={`nav-item ${active == 'News' ? 'activebg' : ''}`} >
                            <a onClick={() => navigate(`/newsAdmin?username=${username}`)} className="nav-link align-middle text-white px-0 d-flex"
                                style={{ cursor: 'pointer' }}
                            >
                                <i className={`fa-regular fa-newspaper ${active == 'News' ? 'text_black' : ''}`}></i>
                                <span className={`ms-1 hideLabel ${active == 'News' ? 'text_black' : ''}`} style={{ fontSize: '14px' }}>News</span>
                            </a>
                        </li> */}








                    </ul>
                    <hr />
                    {/* <!-- <div className="dropdown pb-4">
                                <a href="#"
                                    className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                                    id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30"
                                        className="rounded-circle">
                                        <span className="d-none d-sm-inline mx-1">loser</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                                    <li><a className="dropdown-item" href="#">New project...</a></li>
                                    <li><a className="dropdown-item" href="#">Settings</a></li>
                                    <li><a className="dropdown-item" href="#">Profile</a></li>
                                    <li>
                                        <hr className="dropdown-divider">
                                    </li>
                                    <li><a className="dropdown-item" href="#">Sign out</a></li>
                                </ul>
                            </div> --> */}
                </div>
            </div>
        </>
    )
}

export default Sidebar