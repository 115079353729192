import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuthDispatch, useAuthState } from "../../../Helper/Context";
import { useEffect, useState } from "react";
import { LoginPageModal } from "./Modal/LoginPageModal";
import { useDispatch, useSelector } from "react-redux";
import { Comment_LikeDisLike_InsertPostAPI, DiscussionForumCommentDeleteAPI, DiscussionForumCommentInsertPostAPI, DiscussionForumTableDataAPI } from "../../../Redux/AdminSlice/DiscussionForumSlice/DiscussionForumSlice";
import { LogoLoader } from "../../../Helper/LogoLoader";
import moment from "moment";
import { CommonDeletePopUp } from "../DashboardPages/Admin/KnowledgeRepositoryReport/Action/CommonDeletePopUp";

export const Discussion = () => {

    const navigate = useNavigate();

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    // console.log("UserID", UserID)
    // console.log("RoleID", RoleID)

    const [searchParams] = useSearchParams();
    const Id = searchParams.get('Id')
    const name = searchParams.get('name')

    const [ShowLoginPage, setShowLoginPage] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [IsLoading, setIsLoading] = useState(false)
    const [Comments, setComments] = useState('')
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [editData, setEditData] = useState([])
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [loading, setloading] = useState(false)

    const handleShowLoginPagePage = () => {
        setShowLoginPage(!ShowLoginPage)
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }

    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }

    const handlePost = () => {
        setIsPost(!IsPost)
        setComments('')
        setShowLoginPage(false)
    }

    const [DiscussionForumCurrentData, setDiscussionForumCurrentData] = useState([])

    useEffect(() => {
        handleGetDiscussionData()
    }, [IsPost, name])

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         handleGetDiscussionData()
    //     }, 2000);
    //     return () => clearInterval(interval);
    // }, [])


    const handleGetDiscussionData = () => {
        const data = {
            M_DiscussionForumID: (name && name != undefined && name == "AllSubjectID") ? Id : 0,
            M_UserID: UserID ? UserID : 0,
            Flag: "TopSubject",
            Top: 0,
            token: token,
            setDiscussionForumData: setDiscussionForumCurrentData

        }
        dispatch(DiscussionForumTableDataAPI({ data }))
    }

    const { tableData, isLoading } = useSelector(state => state.DiscussionForumTableData)

    const currentDate = new Date();

    // console.log("DiscussionForumCurrentData", DiscussionForumCurrentData)

    const handleSubmit = () => {
        setIsLoading(true)
        const data = {
            M_FinancialYearID: currentDate.getFullYear(),
            M_MonthID: currentDate.getMonth() + 1,
            M_DiscussionForumID: DiscussionForumCurrentData[0]?.m_DiscussionForumID,
            Comments: Comments,
            M_UserID: UserID ? UserID : 0,
            token: token,
            handleIsPost: handlePost,
            setIsLoading: setIsLoading
        }
        dispatch(DiscussionForumCommentInsertPostAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.DiscussionForumCommentInsertData)

    const handleLikeDislike = (value, comment) => {
        const data = {
            M_FinancialYearID: currentDate.getFullYear(),
            M_MonthID: currentDate.getMonth() + 1,
            M_DiscussionForumID: DiscussionForumCurrentData[0]?.m_DiscussionForumID,
            M_DiscussionForum_CommentID: comment?.m_DiscussionForum_CommentID,
            IsLike: value == 'Like' ? '1' : '0',
            IsDisLike: value == 'DisLike' ? '1' : '0',
            M_UserID: UserID ? UserID : 0,
            token: token,
            handleIsPost: handlePost,
            setIsLoading: setIsLoading
        }
        dispatch(Comment_LikeDisLike_InsertPostAPI({ data }))
    }

    const handleDelete = () => {
        // console.log("m_DiscussionForumID", editData?.m_DiscussionForumID)
        setloading(true)
        const data = {
            M_DiscussionForum_CommentID: editData?.m_DiscussionForum_CommentID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handlePost,
            handlePublishCloseClick: handlePublishCloseClick,
            setloading: setloading
        }
        dispatch(DiscussionForumCommentDeleteAPI({ data }))
    }
    const { DeletetableData, isDeleteLoading } = useSelector(state => state.DiscussionForumCommentDeleteData)

    return (
        <>
            {(loading || isLoading) && <LogoLoader />}
            {isDeleteLoading && <LogoLoader />}

            <div className="row p-lg-5">

                <div className="row col-12 col-lg-8">
                    <div className="col-8">
                        <h5 className="pb-0 mb-0 text-capitalize">{DiscussionForumCurrentData[0]?.subject}</h5>
                        <small>{DiscussionForumCurrentData[0]?.createdDateTime ? moment(DiscussionForumCurrentData[0]?.createdDateTime).format('DD-MM-YYYY') : '-'}
                        </small>
                        <small> {DiscussionForumCurrentData[0]?.createdDateTime ? moment(DiscussionForumCurrentData[0]?.createdDateTime).format('hh:mm A') : '-'}</small>
                    </div>

                    <div className="col-4 " style={{ textAlign: 'end' }}>
                        <a className="fw-bold" href="javascript:void(0)"
                            onClick={() => navigate('/DiscussionForum')}
                        >View Latest Discussion</a>
                    </div>

                    {
                        (UserID != '' && sessionStorage.getItem('GPlaNET') != null) ?
                            <div className="row">
                                <div className="col-9 pt-3 px-0">
                                    <div class="mb-3">
                                        {/* <label for="exampleFormControlTextarea1" class="form-label fw-bold text-dark">Comments</label> */}
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="2"
                                            placeholder="Add a comments..."
                                            name="Comments"
                                            value={Comments}
                                            onChange={(e) => setComments(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center mt-0">
                                    <button
                                        type="button"
                                        className="btn btn-success float-end"
                                        disabled={Comments == ''}
                                        style={{ fontWeight: 'normal' }}
                                        onClick={handleSubmit}
                                    >  Add Comment</button>
                                </div>

                            </div>
                            :
                            <div className="my-3">
                                <text className="mt-5 text-primary"> To post a comment, you need to login. <span className="fw-bold cursor-pointer text-decoration-underline" onClick={() => handleShowLoginPagePage()}>Click here to login</span></text>
                            </div>

                    }

                    <div className=" mt-3 mb-3">
                        {
                            DiscussionForumCurrentData && DiscussionForumCurrentData.length > 0 ? DiscussionForumCurrentData.map((i, j) => {
                                return (
                                    <div key={j}>
                                        {i.dataList.length > 0 ? i.dataList.map((comment, index) => (
                                            <div className="p-3 mb-3" key={index}
                                                style={{
                                                    border: '1px solid rgb(199, 198, 198)'
                                                    // backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#dafbe1',
                                                }}
                                            >
                                                <div className="d-flex">
                                                    {/* <div className="flex-shrink-0  pe-3" >
                                                        <img src="assets/img/user.png" alt="..." style={{height:"4vh"}} />
                                                        <i className="fa-solid fa-face-smile text-info"></i>
                                                    </div> */}
                                                    <div className="flex-grow-1 ms-0" >

                                                        <span className="d-block py-2 px-3" style={{ background: '#f5f5f5', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                                            <span className="pe-2 text-dark ">
                                                                <img src="assets/img/user.png" className="mt-n1" alt="..." style={{ height: "3vh", width: 'auto', paddingRight: '8px' }} />
                                                                <span className=" text-capitalize">{comment.tableUserName ? comment.tableUserName : '-'}</span><br />
                                                                <small className=" text-capitalize" style={{ fontSize: '14px', color: 'gray', marginLeft: '3.7%' }}>
                                                                    {comment.organizationName ? comment.organizationName : ''}
                                                                </small>
                                                            </span>

                                                            <span className="float-end ps-3 mt-n4 pt-0"> <i class="fa-regular fa-calendar pe-1"></i>  <small>{comment.commentDateTime ? moment(comment.commentDateTime).format('DD-MM-YYYY') : '-'} </small>
                                                                <small className="ps-2 "> {comment.commentDateTime ? moment(comment.commentDateTime).format('hh:mm A') : '-'}</small>
                                                            </span>

                                                        </span>
                                                        <p className=" pt-3 fs-7 ps-3"><span className="text-gray">Comment</span>: <span className="text-dark">{comment.comment ? comment.comment : '-'}</span></p>
                                                        <p className="mb-1">
                                                            <span className="border rounded px-3 py-1" style={{ background: '#f2f2f2' }}>
                                                                <i className="fa-solid fa-thumbs-up cursor-pointer" style={{ color: "green" }}
                                                                    onClick={() => handleLikeDislike('Like', comment)}
                                                                ></i>
                                                                <span className="ps-1 pe-2 countText" >{comment.isLikeCount ? comment.isLikeCount : '-'}</span>
                                                                <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> </span>
                                                                <i className="fa-solid fa-thumbs-down fa-flip-horizontal cursor-pointer" style={{ color: "orange" }}
                                                                    onClick={() => handleLikeDislike('DisLike', comment)}
                                                                ></i>
                                                                <span className="ps-1 pe-2 countText" >{comment.isDisLikeCount ? comment.isDisLikeCount : '-'}</span>
                                                                {
                                                                    (UserID == comment?.m_UserID || RoleID == 1) &&
                                                                    <>
                                                                        <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> </span>
                                                                        <i className="fa-solid fa-trash-can fs-6 cursor-pointer" title='Delete' style={{ color: 'red' }}
                                                                            onClick={() => handleDeletePopUp(comment)}
                                                                        ></i>
                                                                    </>
                                                                }

                                                            </span>
                                                        </p>

                                                    </div>
                                                </div>
                                                {/* <p>
                                                    <span className="px-2 text-dark">
                                                        <i className="fa-solid fa-face-smile text-info"></i> <span className=" text-capitalize">{comment.tableUserName ? comment.tableUserName : '-'}</span> </span>
                                                    <small>
                                                        {comment.commentDateTime ? moment(comment.commentDateTime).format('DD-MM-YYYY') : '-'}
                                                    </small>
                                                    <small> {comment.commentDateTime ? moment(comment.commentDateTime).format('hh:mm A') : '-'}</small>
                                                </p>
                                                <p className="text-dark">{comment.comment ? comment.comment : '-'}</p>
                                                <p className="mb-1">
                                                    <span className="border rounded px-3 py-1" style={{ background: '#f2f2f2' }}>
                                                        <i className="fa-solid fa-thumbs-up cursor-pointer" style={{ color: "green" }}
                                                            onClick={() => handleLikeDislike('Like')}
                                                        ></i>

                                                        <span className="ps-1 pe-2 countText" >{comment.isLikeCount ? comment.isLikeCount : '-'}</span>

                                                        <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> </span>

                                                        <i className="fa-solid fa-thumbs-down fa-flip-horizontal cursor-pointer" style={{ color: "orange" }}
                                                            onClick={() => handleLikeDislike('DisLike')}
                                                        ></i>

                                                        <span className="ps-1 pe-2 countText" >{comment.isDisLikeCount ? comment.isDisLikeCount : '-'}</span>
                                                    </span>
                                                </p> */}
                                            </div>
                                        ))
                                            : "Let’s hear from you, be the first to comment!"
                                        }
                                    </div>
                                )

                            })
                                : 'No data'
                        }

                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div>
                        <a className="fw-bold" href="javascript:void(0)" onClick={() => navigate('/ViewAllSubject')}>View All Subjects</a>
                    </div>
                    <div>
                        <small>Questions and Answers</small>
                    </div>

                    <hr />

                    <div>


                        {
                            DiscussionForumCurrentData && DiscussionForumCurrentData.length > 0 ? DiscussionForumCurrentData.map((i, j) => {
                                // const uniqueUserNames = new Set(i.dataList.map(comment => comment.tableUserName));    
                                const uniqueUserNames = i.dataList.filter((comment, index, self) =>
                                    comment.tableUserName && self.findIndex(c => c.tableUserName == comment.tableUserName) == index
                                ).map(comment => comment.tableUserName);
                                // { console.log('uniqueUserNames', uniqueUserNames) }
                                return (
                                    <div key={j}>
                                        {
                                            uniqueUserNames && uniqueUserNames.length > 0 &&
                                            <>
                                                <p className="text-dark fw-bold ">{uniqueUserNames.length} Participants</p>
                                                {uniqueUserNames.map((userName, index) => (
                                                    <div key={index} className="col-12 p-2">
                                                        <img src="assets/img/user.png" alt="..." style={{ height: "3vh" }} /><span className="text-capitalize ps-2"> {userName ? userName : '-'}</span>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                )

                            })
                                : <>No data</>
                        }

                    </div>

                </div>

            </div>

            {
                ShowLoginPage && <LoginPageModal
                    handleShowLoginPagePage={handleShowLoginPagePage}
                    handlePost={handlePost}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    Lable='Discussion Forum Comment'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={loading}
                />
            }
        </>
    )
}