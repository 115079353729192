

import { LazyLoadImage } from "react-lazy-load-image-component"
import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"

export const FAQ = () => {
    return (
        <div class="container-fluid g-0">
            <Header active='FAQ'/>

            <PagesHeroSection
                Heading='FAQ'
                BreadScrum1='Home'
                // BreadScrum2='Resource'
            />


            <div className="row px-3 px-lg-5 pb-5  mx-auto " id="map">
            <div className="col-12 col-md-5 d-flex justify-content-center align-items-center ">
                    <LazyLoadImage effect="blur" src="https://ik.imagekit.io/gplanet/Gplanet/img/FAQS/questions-and-answers.gif?updatedAt=1701510162740" alt="" width="80%" srcset="" className="pc_none" />
                </div>
                <div className="col-12 py-5 pb-lg-4">
                    <h2 className="lightgreencolor">Frequently Asked Questions (FAQs)</h2>
                </div>
                <div className="col-12 col-md-7 mx-auto">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    1. What is GGCI ?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    GGCI stands for the Global Green Credit Initiative. It's an international initiative fostering environmental consciousness and sustainability through various programs and collaborations.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    2. How can I participate in GGCI initiatives?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    You can participate by expressing interest through our portal, engaging in dialogues, contributing resources, or hosting events aligned with our environmental goals.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    3. What types of dialogues and events does GGCI host?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    GGCI hosts dialogues involving governments, organizations, and think-tanks to share insights, discuss solutions, and promote best practices for environmental protection and sustainability.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    4. How can Countries collaborate with GGCI?
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Countries can collaborate by expressing their interest, contributing to the Info-Hub, hosting dialogues, or participating in the Green Credits Programme through our portal.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    5. Is GGCI affiliated with specific environmental programs or countries?
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    While GGCI collaborates globally, it does connect with initiatives like India's Green Credits Programme. It's not limited to any single program or country.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    6. What are the benefits of tree plantation for the environment?
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Tree plantation helps combat climate change by absorbing carbon dioxide, improving air quality, preventing soil erosion, providing habitats for wildlife, and contributing to the overall health of ecosystems.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSeven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    7. Are there educational resources available through GGCI for learning more about environmental sustainability?
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Yes, our website hosts an Info-Hub with resources, articles, and educational materials related to environmental sustainability, tree plantation, and best practices.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingEight">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    8. What types of trees are planted in Green Credit initiatives?
                                </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    We focus on planting native tree species suitable for local ecosystems, considering factors like climate, soil, and biodiversity enhancement.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingNine">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                   9. How can I contact GGCI for more information or inquiries? 
                                </button>
                            </h2>
                            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                You can reach out to us through our contact page on the website or email us. Our team will respond to your inquiries promptly.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-5 d-flex justify-content-center align-items-center ">
                    <LazyLoadImage effect="blur" src="https://ik.imagekit.io/gplanet/Gplanet/img/FAQS/questions-and-answers.gif?updatedAt=1701510162740" className="mobile_none" alt="" width="80%" srcset="" />
                </div>
            </div>

            {/* <div class="row">
                <div class="col-12 ">


                    <div class="offcanvas offcanvas-bottom rounded rounded-3 me-3 mb-3" tabindex="-1" id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel">
                        <div class="offcanvas-header lightgreenbg">
                            <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                                <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /><span class="ps-3 text-white">Green
                                    Planet</span>
                            </h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body small">
                            <div class="col-12 px-4 py-3">
                                <form>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Name:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Number:</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Email ID:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Query:</label>
                                        <textarea class="form-control" rows="5" placeholder="Leave a comment here"
                                            id="floatingTextarea"></textarea>

                                    </div>
                                    <a href="#" class="all_button">Submit</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="row" id="offcanvas_button">
                <div class="col-12">
                    <button class="btn lightgreenbg back-to-top2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                        style={{ borderRadius: '100px', padding: '10px 9px' }}
                    > <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /></button>
                </div>
            </div> */}

            <Footer />
        </div>
    )
}