
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AdminWorldMap } from '../../../../WorldMap/AdminWorldMap';

export const HomePartnerMap = () => {
    return (
        <>
            <div className="row ">
                <div className="col-12 text-center py-3 pb-21 gray_bg ">
                    <h2 className="lightgreencolor mb-0">Active Participant Country In Global Green Credit Initiative</h2>
                </div>
            </div>
            <div className="col-12 pt-0 pt-lg-4">

                <AdminWorldMap />

                {/* <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/india-01.jpg?updatedAt=1701510155627" alt="About" width="100%" height='100%'/> */}

            </div>
    
        </>
    )
}