
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";

// Get Table Data
export const PartnerCaseStudyTableDataAPI = createAsyncThunk("PartnerCaseStudyTableData", async ({ data }) => {
    const {
        M_KnowledgeRepository_CaseStudiesID,
        M_PartnerRegistrationID,
        CaseDate,
        M_UserID,
        Flag,
        token,
        From,
        To,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_KnowledgeRepository_CaseStudies_Select?M_KnowledgeRepository_CaseStudiesID=${M_KnowledgeRepository_CaseStudiesID ? M_KnowledgeRepository_CaseStudiesID : '0'}&M_PartnerRegistrationID=${M_PartnerRegistrationID ? M_PartnerRegistrationID : '0'}&CaseDate=${CaseDate ? CaseDate : ''}&M_UserID=${M_UserID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const PartnerCaseStudyTableDataSlice = createSlice({
    name: "PartnerCaseStudyTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerCaseStudyTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartnerCaseStudyTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PartnerCaseStudyTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PartnerCaseStudyTableDataReducer = PartnerCaseStudyTableDataSlice.reducer

// InsertUpdate Table Data

export const PartnerCaseStudyPostAPI = createAsyncThunk("PartnerCaseStudyPost", async ({ data }) => {
    const {
        M_PartnerRegistrationID,
        CaseDate,
        CaseStudiesSubject,
        PDF_Doc,
        Flag,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setisPostLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_PartnerRegistrationID", M_PartnerRegistrationID);
    formdata.append("CaseDate", CaseDate);
    formdata.append("CaseStudiesSubject", CaseStudiesSubject);
    formdata.append("PDF_Doc", PDF_Doc)
    formdata.append("Flag", Flag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    setisPostLoading(true)
    fetch(`${BaseUrl}/Master/Post_M_KnowledgeRepository_CaseStudies_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setisPostLoading(false)
            return result

        })
})

const PartnerCaseStudyPostSlice = createSlice({
    name: "PartnerCaseStudyPost",
    initialState: {
        isLoading: false,
        PartnerCaseStudyPostData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerCaseStudyPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartnerCaseStudyPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.PartnerCaseStudyPostData = action.payload;
        });
        builder.addCase(PartnerCaseStudyPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.PartnerCaseStudyPostData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartnerCaseStudyPostReducer = PartnerCaseStudyPostSlice.reducer

export const PartnerCaseStudyPatchAPI = createAsyncThunk("PartnerCaseStudyPatch", async ({ data }) => {
    const {
        M_KnowledgeRepository_CaseStudiesID,
        ActionID,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_KnowledgeRepository_CaseStudiesID", M_KnowledgeRepository_CaseStudiesID);
    formdata.append("ActionID", ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Admin_M_KnowledgeRepository_CaseStudies_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const PartnerCaseStudyPatchSlice = createSlice({
    name: "PartnerCaseStudyPatch",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerCaseStudyPatchAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartnerCaseStudyPatchAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PartnerCaseStudyPatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartnerCaseStudyPatchReducer = PartnerCaseStudyPatchSlice.reducer


// Delete Table Data

export const PartnerCaseStudyDeleteAPI = createAsyncThunk("PartnerCaseStudyDelete", async ({ data }) => {

    const {
        M_KnowledgeRepository_CaseStudiesID,
        M_UserID,
        handleIsPost,
        token,
        handleAddCloseClick,
        setIsDeleteLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_KnowledgeRepository_CaseStudiesID", M_KnowledgeRepository_CaseStudiesID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        body: formdata,
        redirect: 'follow'
    };
 
    fetch(`${BaseUrl}/Master/M_KnowledgeRepository_CaseStudies_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsDeleteLoading(false)
                handleIsPost()
                toastSuccesss(result.message)
                handleAddCloseClick()
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            setIsDeleteLoading(false)
            return result

        })
})

const PartnerCaseStudyDeleteSlice = createSlice({
    name: "PartnerCaseStudyDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerCaseStudyDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(PartnerCaseStudyDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(PartnerCaseStudyDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartnerCaseStudyDeleteReducer = PartnerCaseStudyDeleteSlice.reducer




