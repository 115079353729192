import React, { useState } from 'react'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'

export const ShowViewImageModal = (props) => {
    const { openModal, handleIsPost, editData, Flag } = props

    console.log("editData", editData)

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    return (
        <div class="modal show fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Event
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            data-bs-dismiss="modal" onClick={openModal} aria-label="Close"> &times; </button>
                    </div>
                    <div class="modal-body px-3 pt-4" style={{ overflowY: 'scroll' }}>
                        {
                            (Flag == "PressRelease" ? editData?.newsLetterDoc : editData?.eventPhoto) ?
                                Flag == "PressRelease" ?
                                    <img
                                        src={editData?.newsLetterDoc}
                                        alt="Uploaded Preview"
                                        style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                                    />
                                    :
                                    <img
                                        src={editData?.eventPhoto}
                                        alt="Uploaded Preview"
                                        style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                                    />
                                :
                                <img
                                    src="assets/img/event/no_img-01.jpg"
                                    alt="Uploaded Preview"
                                    style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                                />
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}