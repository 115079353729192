import moment from "moment"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"
import { CommonSamplePDFUploadPostAPI } from "../../Redux/AdminSlice/SampleReport/SampleReportSlice"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAuthState } from "../../Helper/Context"
import { useDispatch } from "react-redux"
import { toastErrorr } from "../../Helper/ToastMessage"
import { convertAfterImageBase64 } from "../../Helper/Base64Converter/Base64ConverterFiles"

export const SampleReportPopUp = (props) => {
    const { handleSampleReportViewPopup, heading, tableData, handleIsPost,Heading } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [params] = useSearchParams()
    const IndicatorName = params.get('IndicatorName');

    const [isPostLoading, setisPostLoading] = useState(false)
    const [Subject, setSubject] = useState('')
    const [ReportDate, setReportDate] = useState('')
    const [Document, setDocument] = useState(null)
    const [YesConformCheckbox, setYesConformCheckbox] = useState(false)
    const [SamplePDFPostLoading, setSamplePDFPostLoading] = useState(false)
    const [SamplePDFDocument, setSamplePDFDocument] = useState(null)
    const [SampleDocumentType, setSampleDocumentType] = useState(null)
    const [SamplePDFErrorMessage, setSamplePDFErrorMessage] = useState('')

    const handleOpenPDF = () => {
        if (tableData && tableData[0]?.sampleReport_Path != null) {
            window.open(tableData[0]?.sampleReport_Path,);
        } else {
            toastErrorr('Sample Report is not available!');
        }
    }

    // const handleSamplePDFUPloadFileInputChange = (e) => {
    //     let file = e.target.files[0];
    //     return new Promise(resolve => {
    //         let baseURL = "";
    //         let reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             baseURL = reader.result;
    //             // console.log(reader.result.split(',')[1])
    //             const type = baseURL.split(';')[0].split('/')[1];
    //             setSampleDocumentType('.' + type)
    //             setSamplePDFDocument(reader.result.split(',')[1])
    //             resolve(baseURL);
    //             // console.log('SampleDocumentType',type)
    //         };
    //     });
    // };

    const handleSamplePDFUploadFileInputChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) {
                setSamplePDFErrorMessage('File size exceeds 3 MB. Please upload a PDF less than 3 MB.');
                return;
            }
            setSamplePDFErrorMessage('');

            try {
                const base64 = await convertAfterImageBase64(file);
                const type = base64.split(';')[0].split('/')[1];
                setSampleDocumentType('.' + type);
                setSamplePDFDocument(base64.split(',')[1]);

            } catch (error) {
                console.error('Error converting file to Base64', error);
                setSamplePDFErrorMessage('An error occurred while uploading the file.');
            }
        }
    };

    const handleSamplePDFPost = () => {
        const data = {
            M_FinancialYearID: moment().format('YYYY'),
            M_MonthID: moment().format('MM'),
            SampleReport_M_IndicatorID: IndicatorName,
            SampleReport_Path: SamplePDFDocument,
            SampleReport_FileExtension: SampleDocumentType,
            handleIsPost: handleIsPost,
            M_UserID: UserID,
            token: token,
            setSamplePDFPostLoading: setSamplePDFPostLoading
        }
        dispatch(CommonSamplePDFUploadPostAPI({ data }))
    }
    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '45%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">{Heading}
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleSampleReportViewPopup}> &times; </button>
                    </div>
                    <div class="modal-body" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                <div class="col-12 col-md-6 col-lg-12">
                                    <label for="exampleInputEmail1" class="form-label pt-1">View PDF <AstricSign />
                                        <a style={{ fontSize: '13px', textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                            onClick={handleOpenPDF}>(Click to View)</a>
                                    </label>
                                    {/* <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        onChange={handleFileInputChange}
                                    />
                                    <small className="text-danger" style={{ fontSize: '12px' }}>Upload a PDF file less than 3 MB</small> */}
                                </div>
                                {
                                    RoleID == 1 &&
                                    <>
                                        <div class="col-12 col-md-6 col-lg-8 py-2 border-top ">
                                            <label for="exampleInputEmail1" class="form-label pt-1">Sample Report<AstricSign />
                                            </label>
                                            <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" accept=".pdf"
                                                onChange={(e) => handleSamplePDFUploadFileInputChange(e)}
                                            />
                                            {SamplePDFErrorMessage ?
                                                <div className="row">
                                                    <div className="col-12 alert alert-danger mt-3">
                                                        <span style={{ fontSize: "14px" }}><strong>Error : </strong> {SamplePDFErrorMessage}</span>
                                                    </div>
                                                </div>
                                                :
                                                <small className="text-danger mt-2" style={{ fontSize: '12px' }}>Upload a PDF file less than 3 MB</small>

                                            }

                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 border-top pt-5 mt-lg-n1">
                                            <button class="btn btn-primary text-white fw-200 px-3 fs-6 mx-3" type="submit"
                                                disabled={SamplePDFPostLoading || SamplePDFDocument == null || SamplePDFDocument == ''}
                                                onClick={handleSamplePDFPost}>
                                                {SamplePDFPostLoading && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {!SamplePDFPostLoading && <span><i class="fa-solid fa-floppy-disk pe-1"></i> Save</span>}
                                            </button>
                                        </div>
                                    </>
                                }

                            </div>

                            {/* <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-center mt-4 " >
                                    <button type="button"
                                        className="btn btn-danger"
                                        style={{ width: '80px' }}
                                        onClick={handleSampleReportViewPopup}
                                    > <i class="fa-solid fa-xmark pe-1"></i> No</button>
                                </div>
                            </div> */}
                        </form>
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}