import { useDispatch, useSelector } from "react-redux"
import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"
import { useAuthState } from "../../../Helper/Context"
import { DiscussionForumTableDataAPI } from "../../../Redux/AdminSlice/DiscussionForumSlice/DiscussionForumSlice"
import { useEffect, useState } from "react"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { Pegination } from "../../../Components/Pagination/Pagination"

export const ViewAllSubject = () => {

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const navigate = useNavigate()

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [AllSubjectDiscussionForumData, setAllSubjectDiscussionForumData] = useState([])

    useEffect(() => {
        const data = {
            M_DiscussionForumID: 0,
            M_UserID: UserID ? UserID : 0,
            Flag: "AllSubject",
            Top: '99999',
            token: token,
            setTotalCount: setTotalCount,
            setDiscussionForumData: setAllSubjectDiscussionForumData
        }
        dispatch(DiscussionForumTableDataAPI({ data }))
        window.scrollTo(0, 0)
    }, [To])

    const { tableData, isLoading } = useSelector(state => state.DiscussionForumTableData)

    const handleNavigate = (Id, name) => {

        navigate(`/DiscussionForum?Id=${Id}&name=AllSubjectID`)
    }

    return (
        <>

            <Header active='Engage' />
            <PagesHeroSection
                Heading='Discussion Forum'
                BreadScrum1='Home'
                BreadScrum2='Engage'
            />

            <div className="row p-lg-5">
                <div className="col-12">
                    <h5 className="pb-3 ps-lg-5 ms-1">All Subject's</h5>

                    <div className="d-flex">
                        {/* <div className="nav flex-column nav-pills me-3 col-lg-3" >
                            <button className="nav-link active text-start">Q & A</button>
                        </div> */}

                        <div className="col-12 col-lg-11 mx-auto" >
                            <div className=" ">


                                {
                                    AllSubjectDiscussionForumData && AllSubjectDiscussionForumData.length > 0 ? AllSubjectDiscussionForumData.map((i, j) => {
                                        return (
                                            <div key={j}>
                                                {/* {i.dataList.map((comment, index) => ( */}
                                                <div className="p-3 ps-4 mb-3"
                                                    onClick={() => handleNavigate(i?.m_DiscussionForumID)}
                                                    style={{
                                                        border:'1px solid rgb(199, 198, 198)'
                                                        // backgroundColor: j % 2 === 0 ? '#f9f9f9' : '#dafbe1',
                                                    }}>
                                                    <div className="d-flex">
                                                        <div className="  d-flex align-items-center" >
                                                            {/* <img src="assets/img/ProfileCommen.jpg" alt="..." style={{height:"6vh", width:"3vw",borderRadius:"100%"}} /> */}
                                                            <img src="assets/img/user.png" alt="..." style={{height:"4vh"}} />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3 border-3 border-start">

                                                            <span className="d-block py-2 ps-4" style={{background:'#f5f5f5', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                                                <span className="pe-2 text-dark fs-7">
                                                                    Admin</span>
                                                                    <span className="float-end pe-3">
                                                                <small><i class="fa-regular fa-calendar pe-1"></i> {i.createdDateTime ? moment(i.createdDateTime).format('DD-MM-YYYY') : '-'} </small>
                                                                <small className="ps-2"> {i.createdDateTime ? moment(i.createdDateTime).format('hh:mm A') : '-'}</small>
                                                                </span>
                                                            </span>
                                                            <p className="fs-7 cursor-pointer  ps-4 pt-3 mb-0"><span className="text-gray ">Subject:</span> <span className="text-dark ">{i.subject ? i.subject : '-'}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ))} */}
                                            </div>
                                        )

                                    })
                                        : <>No data</>
                                }
                            </div>


                            {/* {AllSubjectDiscussionForumData && AllSubjectDiscussionForumData.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={TotalCount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    setrowNo={setrowNo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            } */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}

{/* <span className="pe-2 text-dark fw-bold">
                                                        <i className="fa-solid fa-face-smile text-info"></i></span>
                                                    <span>
                                                        <span className="pe-2 text-dark fw-bold">
                                                            Admin</span>
                                                        <small> {i.createdDateTime ? moment(i.createdDateTime).format('DD-MM-YYYY') : '-'} </small>
                                                        <small> {i.createdDateTime ? moment(i.createdDateTime).format('hh:mm A') : '-'}</small>
                                                    </span> 
                                                    <p className="text-dark cursor-pointer">{i.subject ? i.subject : '-'}</p>
                                                    <p className="mb-1">
                                                        <span className="border rounded px-2 py-0">
                                                            <i className="fa-solid fa-thumbs-up text-dark"></i>
                                                            <span className="ps-1 pe-2" style={{ fontSize: '12px' }}>{}</span>
                                                            <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i className="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                                            <span className="ps-1 pe-2">0</span>
                                                        </span> 
                                                    </p> */}
