

export const EventData=[
        {
          "rowNum": 1,
          "totalCount": 35,
          "m_EventID": 4,
          "m_EventTypeID": 1,
          "eventType": "Upcoming",
          "m_EventSubTypeID": 1,
          "eventSubType": "Workshop",
          "m_PartnerRegistrationID": 1,
          "eventStartDateTime": "2024-12-25T00:00:00",
          "eventEndDateTime": "2024-12-28T00:00:00",
          "eventSubject": "t",
          "eventDescription": "t",
          "shortDescription": "t",
          "location": "pune",
          "statusName": 4,
          "statusID": "Published",
          "eventTime": "02:22:00",
          "eventPhoto": null
        },
        {
          "rowNum": 2,
          "totalCount": 35,
          "m_EventID": 1,
          "m_EventTypeID": 1,
          "eventType": "Upcoming",
          "m_EventSubTypeID": 1,
          "eventSubType": "Workshop",
          "m_PartnerRegistrationID": 1,
          "eventStartDateTime": "2024-12-26T00:00:00",
          "eventEndDateTime": "2024-12-27T00:00:00",
          "eventSubject": "Green Golbal",
          "eventDescription": "test",
          "shortDescription": null,
          "location": "pune",
          "statusName": 4,
          "statusID": "Published",
          "eventTime": "12:59:00",
          "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
        },
        {
          "rowNum": 3,
          "totalCount": 35,
          "m_EventID": 1,
          "m_EventTypeID": 1,
          "eventType": "Upcoming",
          "m_EventSubTypeID": 1,
          "eventSubType": "Workshop",
          "m_PartnerRegistrationID": 1,
          "eventStartDateTime": "2024-12-26T00:00:00",
          "eventEndDateTime": "2024-12-27T00:00:00",
          "eventSubject": "Green Golbal",
          "eventDescription": "test",
          "shortDescription": null,
          "location": "pune",
          "statusName": 4,
          "statusID": "Published",
          "eventTime": "12:59:00",
          "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
        },
        {
          "rowNum": 4,
          "totalCount": 35,
          "m_EventID": 1,
          "m_EventTypeID": 1,
          "eventType": "Upcoming",
          "m_EventSubTypeID": 1,
          "eventSubType": "Workshop",
          "m_PartnerRegistrationID": 1,
          "eventStartDateTime": "2024-12-26T00:00:00",
          "eventEndDateTime": "2024-12-27T00:00:00",
          "eventSubject": "Green Golbal",
          "eventDescription": "test",
          "shortDescription": null,
          "location": "pune",
          "statusName": 4,
          "statusID": "Published",
          "eventTime": "12:59:00",
          "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
        },
        {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          },
          {
            "rowNum": 1,
            "totalCount": 35,
            "m_EventID": 4,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-25T00:00:00",
            "eventEndDateTime": "2024-12-28T00:00:00",
            "eventSubject": "t",
            "eventDescription": "t",
            "shortDescription": "t",
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "02:22:00",
            "eventPhoto": null
          },
          {
            "rowNum": 2,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\1.jpg"
          },
          {
            "rowNum": 3,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\3.jpg"
          },
          {
            "rowNum": 4,
            "totalCount": 35,
            "m_EventID": 1,
            "m_EventTypeID": 1,
            "eventType": "Upcoming",
            "m_EventSubTypeID": 1,
            "eventSubType": "Workshop",
            "m_PartnerRegistrationID": 1,
            "eventStartDateTime": "2024-12-26T00:00:00",
            "eventEndDateTime": "2024-12-27T00:00:00",
            "eventSubject": "Green Golbal",
            "eventDescription": "test",
            "shortDescription": null,
            "location": "pune",
            "statusName": 4,
            "statusID": "Published",
            "eventTime": "12:59:00",
            "eventPhoto": "https://apigplanet.cdat.work/Upload\\Event\\Photo\\11.jpg"
          }
      ]
