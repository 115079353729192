import React, { useEffect, useState } from 'react'
import { convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useAuthState } from '../../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { DiscussionForumPostAPI } from '../../../../../../Redux/AdminSlice/DiscussionForumSlice/DiscussionForumSlice'
import { convertToHTML } from 'draft-convert';

export const DiscussionForumFormPopUp = (props) => {
    const { handleOnAddClick, handleIsPost, heading, handlePublish, Lable, loading } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [isLoading, setisLoading] = useState(false)
    // const [JobDescription, setJobDescription] = useState(() => EditorState.createEmpty());
    const [JobDescription, setJobDescription] = React.useState('')
    const [FilePath, setFilePath] = useState('');
    const [FileExtension, setFileExtension] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [Subject, setSubject] = useState('');
    const [fileName, setFileName] = useState('');
    const [ConvertedContent, setConvertedContent] = React.useState('')

    const handleEditorChange = (state) => {
        setJobDescription(state);
        convertContentToHTML(state);
    }
    const convertContentToHTML = (state) => {
        let currentContentAsHTML = convertToHTML(state.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setFilePath(base64);
            };
            reader.readAsDataURL(file);

            const extension = `.${file.name.split('.').pop()}`;
            setFileExtension(extension);
            setFileName(file.name);
        }
    };

    // console.log("FilePath", FilePath)
    // console.log("FileExtension", FileExtension)

    // const extractSubjectFromEditor = () => {
    //     const contentState = JobDescription.getCurrentContent();
    //     const rawContent = convertToRaw(contentState);
    //     const subjectText = rawContent.blocks.map(block => block.text).join(' ').trim();
    //     return subjectText || 'Untitled';
    // };

    const currentDate = new Date();

    const handleSubmit = () => {
        // const subject = extractSubjectFromEditor();
        setisLoading(true)
        const data = {
            M_FinancialYearID: currentDate.getFullYear(),
            M_MonthID: currentDate.getMonth() + 1,
            // Subject: ConvertedContent,
            Subject: JobDescription,
            DocumentPath: FilePath,
            Document_FileExtension: FileExtension,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisLoading: setisLoading
        }
        dispatch(DiscussionForumPostAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.DiscussionForumPostData)

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialogs modal-dialog-centered" >
                <div class="modal-content modal-xl" >
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Add Discussion Subject
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleOnAddClick}> &times; </button>
                    </div>
                    <div class="modal-body px-5" >
                        <div class="row py-4 bg-light px-3">
                            <div className="col-12 col-md-9 col-lg-11 mx-auto rounded py-2 bg-white" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset' }}>
                                <div className="form-group">
                                    <label className='fw-bold pb-2'>Create New Subject <span style={{ color: "red" }}> *</span></label>
                                    {/* <Editor
                                        editorState={JobDescription}
                                        onEditorStateChange={editorState => {
                                            setJobDescription(editorState);
                                            handleEditorChange(editorState);
                                        }}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorStyle={{ border: "1px solid", height: '200px' }}
<<<<<<< HEAD
                                    /> */}

                                    <div className='mt-2 px-3'>
                                        <textarea
                                            style={{ width: '99%', padding:10 }}
                                            rows={10}
                                            onChange={(e) => setJobDescription(e.target.value)}
                                        />
                                    </div>

                                   
                                    <div className="row mx-0">
                                        <div className='col-lg-6 mt-4'>

                                            <input
                                                type="file"
                                                id="file"
                                                style={{ display: "none" }}
                                                onChange={handleFileChange}
                                            />
                                            
                                            <label htmlFor="file" className='d-flex align-items-center'>
                                                <span className='fa fa-image fs-2 img-hover text-success' title="Upload Image"> </span>
                                                <span className='ps-2 cursor-pointer '>Upload Photo</span>
                                            </label>

                                            {fileName && (
                                                <span className='ps-5'>
                                                    <strong> {fileName}</strong>
                                                </span>
                                            )}

                                        </div>
                                        <div className='col-lg-6 mt-4 mb-3 '>
                                            <button type="button" className="clear_button  px-4 py-2 float-end "
                                                onClick={handleSubmit}> <i class="fa-solid fa-save pe-1"></i> Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>                     </div>
                    </div>

                </div>
            </div>
        </div>

    )
}