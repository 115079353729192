import React, { useState } from 'react'
import { EventTypeDDL } from '../../../../../Components/DDL/EventTypeDDL/EventTypeDDL'
import { EventSubTypeDDL } from '../../../../../Components/DDL/EventSubTypeDDL/EventSubTypeDDL'
import { EventPostAPI } from '../../../../../Redux/AdminSlice/EventSlice/EventSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { AstricSign } from '../../../../../Helper/AstricSign/AstricSign'
import { convertAfterImageBase64 } from '../../../../../Helper/Base64Converter/Base64ConverterFiles'
import moment from 'moment'
import { ShowViewImageModal } from './ShowViewImageModal'

export const AddEvent = (props) => {
    const { handleOnAddClick, handleIsPost, PopUpFlag, editData } = props

    // console.log("editData",editData)

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [isLoading, setisLoading] = useState(false)

    const [YesConformCheckbox, setYesConformCheckbox] = useState(false)
    const [PhotoDocument, setPhotoDocument] = useState('')
    const [PhotoErrorMessage, setPhotoErrorMessage] = useState('');
    const [showImagePreview, setShowImagePreview] = useState(false);

    const handleYesNo = () => {
        setYesConformCheckbox(!YesConformCheckbox)
    }
    const openModal = () => {
        setShowImagePreview(!showImagePreview);
    }

    const [eventTypeDDL, seteventTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [eventSubTypeDDL, seteventSubTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [EventTextField, setEventTextField] = useState({
        EventName: PopUpFlag == 'Update' ? editData?.eventName : '',
        EventStartDate: PopUpFlag == 'Update' ? moment(editData?.eventStartDateTime).format('YYYY-MM-DD') : '',
        EventEndDate: PopUpFlag == 'Update' ? moment(editData?.eventEndDateTime).format('YYYY-MM-DD') : '',
        Location: PopUpFlag == 'Update' ? editData?.location : '',
        Time: PopUpFlag == 'Update' ? editData?.eventTime : '',
        eventDescription: PopUpFlag == 'Update' ? editData?.eventDescription : '',
        shortDescription: PopUpFlag == 'Update' ? editData?.shortDescription : '',
    })

    const onchange = (e) => {
        const { name, value } = e.target
        setEventTextField(pre => ({
            ...pre, [name]: value
        }))
    }

    const handlePhotoInputChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) {
                setPhotoErrorMessage('Photo size exceeds 3 MB. Please upload a Photo less than 3 MB.');
                return;
            }
            setPhotoErrorMessage('');
            try {
                const base64 = await convertAfterImageBase64(file);
                setPhotoDocument(base64.split(',')[1]);


            } catch (error) {
                console.error('Error converting file to Base64', error);
                setPhotoErrorMessage('An error occurred while uploading the file.');
            }
        }
    };

    const handleSubmit = () => {
        const data = {
            M_EventID: PopUpFlag == 'Update' ? editData?.m_EventID : 0,
            M_EventTypeID: eventTypeDDL.ID,
            M_EventSubTypeID: eventSubTypeDDL.ID,
            M_PartnerRegistrationID: 0,
            EventStartDateTime: EventTextField.EventStartDate,
            EventEndDateTime: EventTextField.EventEndDate,
            EventSubject: EventTextField.EventName,
            ShortDescription: EventTextField.shortDescription,
            EventPhoto: PhotoDocument,
            EventDescription: EventTextField.eventDescription,
            location: EventTextField.Location,
            EventTime: EventTextField.Time,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisLoading: setisLoading,
            PopUpFlag: PopUpFlag
        }
        dispatch(EventPostAPI({ data }))
    }

    // const { isLoading } = useSelector(state => state.EventPostData)

    return (
        <div class="modal show fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Event
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div class="modal-body px-3 pt-4" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <EventTypeDDL
                                        eventTypeDDL={eventTypeDDL}
                                        seteventTypeDDL={seteventTypeDDL}
                                        Astric={true}
                                        apiFlag={PopUpFlag}
                                        rowData={editData}

                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <EventSubTypeDDL
                                        eventSubTypeDDL={eventSubTypeDDL}
                                        seteventSubTypeDDL={seteventSubTypeDDL}
                                        Astric={true}
                                        apiFlag={PopUpFlag}
                                        rowData={editData}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Event Name<AstricSign />
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='EventName'
                                        value={EventTextField.EventName}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Start Date<AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id=""
                                        aria-describedby="emailHelp"
                                        name='EventStartDate'
                                        value={EventTextField.EventStartDate}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">End Date<AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='EventEndDate'
                                        value={EventTextField.EventEndDate}
                                        onChange={onchange}
                                        min={EventTextField.EventStartDate}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Location<AstricSign />
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='Location'
                                        value={EventTextField.Location}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Time<AstricSign />
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='Time'
                                        value={EventTextField.Time}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Event Description
                                    </label>
                                    <textarea type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='eventDescription'
                                        value={EventTextField.eventDescription}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Short Description<AstricSign />
                                    </label>
                                    <textarea type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='shortDescription'
                                        value={EventTextField.shortDescription}
                                        onChange={onchange}
                                    />
                                </div>

                                <div className={PopUpFlag == "Update" ? "col-12 col-md-6 col-lg-5 mt-3" : "col-12 col-md-6 col-lg-6 mt-3"}>
                                    <label for="PhotoDocument" className="form-label mb-0">Upload Photo <AstricSign /></label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        id="PhotoDocument"
                                        name="PhotoDocument"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => handlePhotoInputChange(e)}
                                    />

                                    {PhotoErrorMessage ?
                                        <div className="row">
                                            <div className="col-12 alert alert-danger mt-3">
                                                <span style={{ fontSize: "14px" }}><strong>Error : </strong> {PhotoErrorMessage}</span>
                                            </div>
                                        </div>
                                        :
                                        <small className="text-danger mt-2" style={{ fontSize: '12px' }}> Upload Image less than 3 MB</small>

                                    }
                                    {/* <div className="mt-3"> */}

                                    {/* </div> */}

                                </div>
                                {
                                    PopUpFlag == "Update" &&
                                    <div className="col-12 col-md-6 col-lg-1 mt-5">
                                        <span type="button" onClick={() => openModal()} >
                                            <i className="fa fa-eye"></i>
                                        </span>
                                    </div>
                                }

                            </div>

                            {
                                RoleID != 1 &&
                                <>
                                    <div className="col-12 pt-4 px-4">
                                        <p className="">I grant GGCI permission to make the resource I am uploading public on the platform.</p>
                                        <div class="mb-3 form-check">
                                            <input type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                name="YesConformCheckbox"
                                                value={YesConformCheckbox}
                                                onChange={(e) => handleYesNo(e)}
                                            />
                                            <label class="form-check-label " for="exampleCheck1">Yes, I confirm</label>
                                        </div>
                                    </div>

                                </>
                            }

                            <p class="float-end pt-3 pb-2">
                                <button type="button" class="btn  lightgreenbg text-white px-4"
                                    disabled={isLoading || eventSubTypeDDL.ID == 0 || eventTypeDDL.ID == 0 || EventTextField.EventStartDate == '' || EventTextField.shortDescription == '' || (PopUpFlag != 'Update' ? PhotoDocument == '' : editData?.eventPhoto == '') ||
                                        EventTextField.EventEndDate == '' || EventTextField.Location == '' || EventTextField.Time == '' || RoleID != 1 && YesConformCheckbox == false}
                                    onClick={handleSubmit}
                                >{isLoading && (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                    {!isLoading && <span> <i class="fa-solid fa-floppy-disk pe-1"></i> Submit</span>} </button>
                            </p>


                        </form>

                    </div>
                    {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                </div>
            </div>
            {
                showImagePreview &&
                <ShowViewImageModal showImagePreview={showImagePreview} openModal={openModal} handleIsPost={handleIsPost} editData={editData} />
            }
        </div>
    )
}