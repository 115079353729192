
import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import FAB from '../../../../../Components/FAB/FAB'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { LogoLoader } from '../../../../../Helper/LogoLoader'
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { DiscussionForumFormPopUp } from './Modal/DiscussionForumFormPopUp'
import { DiscussionForumDeleteAPI, DiscussionForumTableDataAPI } from '../../../../../Redux/AdminSlice/DiscussionForumSlice/DiscussionForumSlice'
import moment from 'moment'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
export const DiscussionForumForm = () => {

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [Heading, setHeading] = useState('')
    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [UploadDate, setUploadDate] = useState('')

    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState([])
    const [IsDeleteLoading, setIsDeleteLoading] = useState(false)

    // const convertContentToHTML = (state) => {
    //     let currentContentAsHTML = convertContentToHTML(state.getCurrentContent());
    //     setConvertedContent(currentContentAsHTML);
    // }

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [DiscussionForumData, setDiscussionForumData] = useState([])
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [PublishPopUp, setPublishPopUp] = useState(false)


    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }
    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    const handleClear = () => {
        setpartnerNameDDL({
            ...partnerNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setUploadDate('')
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }

    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }

    useEffect(() => {
        const data = {
            M_DiscussionForumID: 0,
            M_UserID: UserID,
            Flag: "AllSubject",
            Top: '99999',
            token: token,
            setDiscussionForumData: setDiscussionForumData

        }
        dispatch(DiscussionForumTableDataAPI({ data }))
    }, [IsPost, UploadDate])

    const { tableData, isLoading } = useSelector(state => state.DiscussionForumTableData)

    const handleDelete = () => {
        // console.log("m_DiscussionForumID", editData?.m_DiscussionForumID)
        setIsDeleteLoading(true)
        const data = {
            M_DiscussionForumID: editData?.m_DiscussionForumID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handlePublishCloseClick: handlePublishCloseClick,
            setIsDeleteLoading: setIsDeleteLoading
        }
        dispatch(DiscussionForumDeleteAPI({ data }))
    }
    const { DeletetableData, isDeleteLoading } = useSelector(state => state.DiscussionForumDeleteData)

    return (
        <>
            {isLoading && <LogoLoader />}
            {isDeleteLoading && <LogoLoader />}

            <AdminHeader />
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='DiscussionForumForm' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> Discussion Forum </h5>
                        </span>
                        <div class="row px-lg-5">
                            <div class="col-12 px-lg-5">
                                <div class="modal fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header px-lg-5 bg-white">
                                                <h5 class="modal-title lightgreencolor" id="exampleModalLabel">News Letter
                                                </h5>
                                                <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                                                    data-bs-dismiss="modal" aria-label="Close"> &times; </button>
                                            </div>
                                            <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                                                <form>
                                                    <div className='row'>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">Month
                                                            </label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>
                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">News Letter
                                                            </label>
                                                            <input type="file" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>

                                                    </div>
                                                    <p class="float-end py-5">
                                                        <button type="button" class="btn  lightgreenbg text-white px-4">Upload </button>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row py-4  card p-2">
                            <div class="col-12">

                                <div class="col bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">
                                        <div class="col-12 col-md-6 col-lg-3 ">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="AssetName"
                                                    value={UploadDate}
                                                    onChange={(e) => setUploadDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-2 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            > <i class="fa-solid fa-xmark pe-1"></i> Clear</button>
                                        </div>

                                        {/* <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                <table>
                                    <thead>
                                        <tr class="header lightgreenbg whitecolor">
                                            <th >Sr. No.</th>
                                            <th>Date</th>
                                            <th>Subject</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            DiscussionForumData && DiscussionForumData.length > 0 ? DiscussionForumData.map((i, j) => {
                                                return (
                                                    <tr width='100%'>
                                                        <td className='text-center' width='5%'>{j + 1}</td>
                                                        <td>{i.createdDateTime ? moment(i.createdDateTime).format('DD-MM-YYYY') : '-'}</td>
                                                        <td>{i.subject ? i.subject : '-'}</td>
                                                        <td width='10%' style={{ textAlign: 'center' }}>
                                                            <span style={{ cursor: 'pointer' }}>
                                                                <i class="fa-solid fa-trash-can fs-6" title='Delete' style={{ color: 'red' }}
                                                                    onClick={() => handleDeletePopUp(i)}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                                : <>No data</>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={tableData.table[0].totalCount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    setrowNo={setrowNo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            } */}
                        </div>

                    </div>
                </div>
            </div>
            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <DiscussionForumFormPopUp handleOnAddClick={handleOnAddClick} handleIsPost={handleIsPost} />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='Discussion Forum'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={IsDeleteLoading}
                />
            }
        </>
    )
}
