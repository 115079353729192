import { useNavigate } from "react-router-dom"
import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"
import { Discussion } from "./Discussion"
import { useAuthDispatch, useAuthState } from "../../../Helper/Context"

export const DiscussionForum = () => {

    return (
        <>
            <Header active='Engage' />
            <PagesHeroSection
                Heading='Discussion Forum'
                BreadScrum1='Home'
                BreadScrum2='Engage'
            />
            <Discussion />
            <Footer />

        </>
    )
}