

import React, { useEffect, useRef, useState } from 'react';
import { create } from '@amcharts/amcharts4/core'; // This is the correct way to import core
import * as am4core from '@amcharts/amcharts4/core'; // Add this line to import am4core
import { MapChart } from '@amcharts/amcharts4/maps';
import am4geodata_worldIndiaLow from '@amcharts/amcharts4-geodata/worldIndiaLow';
import { MapPolygonSeries } from '@amcharts/amcharts4/maps';
import { BaseUrl } from '../../Helper/BaseURL';
import { toastErrorr } from '../../Helper/ToastMessage';

export const AdminWorldMap = () => {
  const chartRef = useRef(null);

  const [data, setdata] = useState([])

  const GetMapData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Get_WEB_DynamicMap_Select?M_FinancialYearID=0&M_MonthID=0&M_CountryNameID=0&M_UserID=1&From=1&To=1000`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code >= 200 && result.code <= 300 && result.data.table) {
          setdata(result.data.table)
        } else {
          setdata([])
          toastErrorr('Something went wrong!')
        }
      })
      .catch((error) => {
        setdata([])
        toastErrorr('Something went wrong!')
        console.error(error)
      });
  }

  useEffect(() => {
    GetMapData()
    // console.log("data", data)
  }, [])

  useEffect(() => {
    // Create the chart once the component mounts
    const chart = create(chartRef.current, MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldIndiaLow; // Low-resolution world map

    // Create the map polygon series to represent countries
    const polygonSeries = chart.series.push(new MapPolygonSeries());
    polygonSeries.useGeodata = true; // Load country data

    // Assign data to polygon series (this will allow dynamic styling)
    // polygonSeries.data = [
    //   {
    //     id: 'IN', // Country ID (India)
    //     name: 'India',
    //     value: 100,
    //     fill: am4core.color("#006939"), // Assign custom color for India
    //   },
    //   {
    //     id: 'US', // Country ID (United States)
    //     name: 'United States',
    //     value: 50,
    //     fill: am4core.color("#006939"), // Assign custom color for US
    //   },
    //   {
    //     id: 'FR', // Country ID (France)
    //     name: 'France',
    //     value: 50,
    //     fill: am4core.color("#006939"), // Assign custom color for France
    //   },
    // ];

    // const mappedData = data.map((item) => (
    //   // console.log("item",item)
    //   {
    //     id: item.m_CountryNameID,
    //     name: item.countryName,
    //     value: item.totalCountryCount || 0,
    //     fill: am4core.color("#006939"),
    //   }
    // ));

    const mappedData = data.length > 0
      ? data.map((item) => (
        {
          id: item?.m_CountryNameID ? item?.m_CountryNameID : '',
          name: item?.countryName ? item?.countryName : '',
          value: item?.totalCountryCount ? item?.totalCountryCount : 0,
          fill: am4core.color("#006939"),
        }
      ))
      : [
        {
          id: "",
          name: "",
          value: 0,
          fill: am4core.color("#F0F0F0"),
        },
      ];

    polygonSeries.data = mappedData;

    // Style the polygons (countries)
    polygonSeries.mapPolygons.template.tooltipText = ' {name}  {value}'; // Tooltip text shows country name
    polygonSeries.mapPolygons.template.stroke = 'black'; // Country borders color

    // Apply the fill property dynamically from the data
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.propertyFields.fill = "fill"; // Dynamically use the 'fill' from the data

    polygonTemplate.fill = am4core.color("#d9d9d9");
    // Add hover effect for countries
    // const hoverState = polygonTemplate.states.create('hover');
    // hoverState.properties.fill = '#367B25'; // Hover color for countries

    // Default active country logic: Make India active
    const activeCountryId = 'IN'; // Default active country (India)

    polygonSeries.mapPolygons.each((polygon) => {
      if (polygon.dataItem.id === activeCountryId) {
        // Set the active country to a different color (Black in this case)
        polygon.fill = am4core.color('#000000'); // Set to black (active state color)
        // Optional: you can add more styling to show it's active
      }
    });

    
    chart.chartContainer.wheelable = false; // Disable Zoom In Out
    // chart.zoomOnDoubleClick = false;
    chart.seriesContainer.draggable = false; // Disable Drag
    chart.seriesContainer.resizable = false;  // Disable Resize
    chart.maxZoomLevel = 1;

    // Return cleanup function to dispose chart on unmount
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div
      ref={chartRef}
      style={{ width: '100%', height: '500px' }}
    ></div>
  );
};

export default AdminWorldMap;

