
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from "react-router-dom"

export const HomeAboutUS = () => {

    const navigate = useNavigate()
    let mount;

    // useEffect(() => {


    //   var scene = new THREE.Scene();
    //   var camera = new THREE.PerspectiveCamera(70, 1, 1, 100);
    //   var renderer = new THREE.WebGLRenderer({ alpha: true });
    //   renderer.setSize(window.innerWidth, window.innerHeight);
    //   // document.body.appendChild( renderer.domElement );
    //   // use ref as a mount point of the Three.js scene instead of the document.body
    //   mount.appendChild(renderer.domElement);
    //   var geometry = new THREE.SphereGeometry(10, 100, 100);
    //   var material = new THREE.MeshPhongMaterial();
    //   THREE.ImageUtils.crossOrigin = "";
    //   material.map = THREE.ImageUtils.loadTexture(''
    //     // material.map = THREE.TextureLoader(
    //         // "https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/earthmap1k.jpg"
    //         // "https://greggman.com/downloads/examples/three.js/examples/textures/earth-large-with-ocean-mask.png"
    //     // "https://raw.githubusercontent.com/aframevr/sample-assets/master/assets/images/space/earth_atmos_4096.jpg"
    //   );
    //   var mesh = new THREE.Mesh(geometry, material);
    //   mesh.rotation.x -= 0;
    //   mesh.position.setX(17);
    //   scene.add(mesh);
    //   camera.position.z = 38;
    //   var light1 = new THREE.AmbientLight(0xffffff);
    //   light1.position.set(100, 50, 100);
    //   scene.add(light1);

    //   var resize = function() {
    //     var width = renderer.domElement.clientWidth;
    //     var height = renderer.domElement.clientHeight;
    //     renderer.setSize(width, height, false);
    //     camera.aspect = width / height;
    //     camera.updateProjectionMatrix();
    //   };

    //   var animate = function() {
    //     resize();
    //     mesh.rotation.y -= 0.002;
    //     renderer.render(scene, camera);
    //     requestAnimationFrame(animate);
    //   };
    //   animate();
    // }, []);

    return (
        <div className="row px-4  px-md-5">
            <div className="col-12 col-lg-6 py-4 d-flex align-items-center justify-content-center">
                <div className="">
                    <h2 className="lightgreencolor ">About Global Green Credit Initiative </h2>
                    {/* <h4 className="my-4">Global Green Credit Initiative </h4> */}

                    <p className='justify'>Global Green Credit Initiative is an essential
                        component of the overall strategy to promote environmental consciousness and sustainability.
                        It serves as the international platform for dialogue, collaboration, and the exchange of
                        innovative environmental programs and instruments.</p>

                    {/* <p><a onClick={() => navigate('/GPlaNET')} className="lightgreencolor fw-bold cursor-pointer"><i
                        className="fa fa-check-circle  me-3"></i>G-PlaNET
                    </a></p> */}

                    {/* <p><a onClick={() => navigate('/gcp')} className="lightgreencolor fw-bold cursor-pointer"><i className="fa fa-check-circle  me-3"></i>GCP
                    </a></p> */}

                    {/* <p><a onClick={() => navigate('/life')} className="lightgreencolor fw-bold cursor-pointer"><i
                        className="fa fa-check-circle  me-3"></i>LiFE</a></p> */}

                </div>
            </div>
            <div className="col-12  col-lg-5 py-lg-5 pb-5 text-center mx-auto">

                {/* <div className="earth" ref={(ref) => (mount = ref)}></div> */}
                <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/globe.jpg?updatedAt=1701510152780" alt="About" width="100%" height='100%' style={{borderRadius: '5px'}}/>
                {/* <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/iStock-1951237945%20(1)%20(1).jpg?updatedAt=1735389077280" alt="About" width="100%" height='100%' style={{borderRadius: '5px'}}/> */}
                {/* <img src="assets/img/globe.jpg" width="100%" alt="" /> */}
            </div>
        </div>
    )
}