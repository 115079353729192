import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout, useAuthState } from '../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { RegistrationPopUp } from '../../Pages/PopUp/RegistrationPopUp'
import { PledgeRegistration } from '../../Pages/PopUp/PledgeRegistration'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function Header(props) {
  const { active } = props

  const dispatch = useDispatch()
  const userDetails = useAuthState()
  const { username, UserID, token, RoleID } = userDetails
  const navigate = useNavigate()

  console.log("userDetails", userDetails)

  const [showRegistration, setshowRegistration] = useState(false)
  const [showPledge, setshowPledge] = useState(false)
  const [refresh, setRefresh] = useState(false);

  const handleShowRegistration = () => {
    setshowRegistration(!showRegistration)
  }

  const handleShowPledge = () => {
    setshowPledge(!showPledge)
  }

  // const LogOut = () => {
  //   logout(dispatch)
  //   navigate(window.location.pathname)

  // }

  const handlePortalMaster = () => {
    if (RoleID == 1) {
      navigate(`/admin?username=admin`)
    } else {
      navigate('/knowledgeRepositoryReport');
    }
  }

  const LogOut = () => {
    logout(dispatch)
    // setIsLoggedIn(false);
    // setRefreshKey(prevKey => prevKey + 1);
    // navigate(sessionStorage.getItem('GPlaNET') != null);
    sessionStorage.removeItem('GPlaNET');
    navigate('/')
    window.location.pathname = window.location.pathname;
    // setRefresh(true)
  };

  return (
    // <div className="container-fluid g-0">
    <div className="row lightgreenbg shadow g-0 nav_position  " id="top_logos"
      onLoad={() => window.scrollTo(0, 0)}>
      <div className="row lightgreenbg shadow g-0 nav_position" id="top_logos">

        <div className="col-12 d-flex  justify-content-between align-items-center mx-auto bg-white top_nav px-2 py-1">
          <div>
            <a onClick={() => navigate('/')} className="navbar-brand g_logo pe-3 ms-2 pc_none ">
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475" alt="" className="top_logos" style={{ width: "70px" }} />
            </a>

            <a onClick={() => navigate('/')} className="navbar-brand g_logo px-3 mobile_none ">
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475" alt="" className="top_logos" style={{ width: "170px" }} />
            </a>

            {/* <a href="index.html" className="navbar-brand g_logo px-2">
              <img src="assets/img/top_logo/G_PlaNET_logo1.png" alt="" className="top_logos" style={{ width: "65px" }} />

            </a> */}

            <span>
              <a onClick={() => navigate('/')} className="text-decoration-none lightgreencolor fw-lighter fs-6 pc_none header_font">
                Global Green Credit Initiative
              </a>
              <a onClick={() => navigate('/')} className="text-decoration-none lightgreencolor fw-lighter fs-4 mobile_none header_font">
                Global Green Credit Initiative
              </a>
            </span>

          </div>

          <div className='row'>
            <div className={(UserID != '' && sessionStorage.getItem('GPlaNET') != null) ? "col-8 d-flex align-items-center" : "col-8 d-flex align-items-center"}>
              <span className=''>{
                (UserID != '' && sessionStorage.getItem('GPlaNET') != null) ?
                  <>
                    <div className="dropdown ">
                      <span onClick={handlePortalMaster} className="text-white cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>Portal Master</span>

                      <span className="cursor-pointer user-dropdown dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://ik.imagekit.io/gplanet/Gplanet/img/profile.png?updatedAt=1701510156922" className=" hero_carousal"
                          alt="..." style={{ height: '30px', width: '30px' }} />
                      </span>

                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item cursor-pointer gray_bg" ><i className="fa-solid fa-user me-2"></i>{username ? username : ''}</a></li>
                        {/* <li><a className="dropdown-item cursor-pointer"><i className="fa-solid fa-key me-2"></i>Change Password</a></li> */}
                        <li><a className="dropdown-item cursor-pointer" onClick={LogOut}><i className="fa-solid fa-power-off me-2"></i>Log Out</a></li>
                      </ul>


                    </div>

                  </>
                  :
                  <>

                    <span onClick={() => navigate('/login')} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }} >Login</span>
                    <span onClick={handleShowRegistration} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>Registration</span>
                  </>
              }
              </span>
            </div>
            <div className={(UserID != '' && sessionStorage.getItem('GPlaNET') != null) ? "col-4 d-flex align-items-center" : "col-4 d-flex align-items-center"}>

              {/* <span onClick={() => navigate('/login')} className="nav-link ms-3 cursor-pointer">Login
              </span> */}

              {/* <span onClick={() => navigate('/login')} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }} >Login</span> */}

              {/* <span onClick={handleShowRegistration} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>Registration</span> */}

              <span>
                <a onClick={() => navigate('/')} className="navbar-brand ps-3 g_logo1 pc_none">
                  {/* assets/img/right_logo.png */}
                  <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos " style={{ width: "30px" }} />
                </a>

                <a onClick={() => navigate('/')} className="navbar-brand ps-3 g_logo1 mobile_none">
                  {/* assets/img/right_logo.png */}
                  <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos " style={{ width: "50px" }} />
                </a>
              </span>
            </div>

          </div>
        </div>

      </div>
      {/* <!--end of col top logos--> */}

      <div className="container " id="navbar">
        <nav className="navbar navbar-expand-lg py-0">
          {/* <span onClick={() => navigate('/')} className="navbar-brand d-none">
            <img src="img/right_logo.png" alt="" style={{ width: "80px" }} />
          </span> */}
          {/* <span onClick={() => navigate('/')} className="navbar-brand d-none">
            <img src="img/G_PlaNET_logo.png" alt="" style={{ width: "70px" }} />
          </span> */}
          <i className="navbar-toggler-icon fa-solid fa-bars text-white fs-2 mobile_bar ms-4 mt-3"
            data-bs-toggle="collapse" data-bs-target="#navbarCollapse"></i>

          {/* <span className="px-2 search_span1 pc_none mx-1">
            <input className=" px-2  search_input1" type="search" id="site-search" placeholder="Search"
              name="q" />
            <span className="icon_top search_icon"><i
              className="fa-solid fa-magnifying-glass text-dark fs-7"></i></span>
          </span> */}
          <span className='d-flex'>
            <span onClick={() => navigate('/login')} className="nav-link ms-2 text-white cursor-pointer pc_none">Login
            </span>

            <span onClick={handleShowRegistration} className="nav-link ms-2 text-white cursor-pointer pc_none">Registration
            </span>
          </span>

          {/* <!-- <span href="#" className="text-white mx-3 pe-2 pc_none ">Log in</span> --> */}

          <div className="collapse navbar-collapse bg-transparent py-2" id="navbarCollapse">
            <div className="navbar-nav ms-auto mx-xl-auto p-0">
              <span onClick={() => { navigate('/'); window.scrollTo(0, 0) }}
                className={active == 'Home' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>Home</span>

              {/* <div className="nav-item dropdown">
                <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">Join the G-PlaNET Pledge
                  <i className="fas fa-chevron-down arrow_down"></i>
                </span>
                <div className="dropdown-menu rounded">
                  <span onClick={handleShowPledge} className="dropdown-item py-2 border-bottom cursor-pointer" >Registration</span>
                  <span onClick={() => navigate('/Participants')}
                    className="dropdown-item py-2 cursor-pointer">Participants</span>
                  <span onClick={() => navigate('/WorldMap')} className="dropdown-item py-2 cursor-pointer">World Map</span>
                </div>
              </div> */}

              {/* <div className="nav-item dropdown">
                <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">About
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu">
                  <span onClick={() => navigate('/GPlaNET')} className="dropdown-item py-2 border-bottom cursor-pointer">G-PlaNET</span>
                  <span onClick={() => navigate('/gcp')} className="dropdown-item py-2 border-bottom cursor-pointer">GCP</span>
                  <span onClick={() => navigate('/life')} className="dropdown-item py-2 cursor-pointer">LiFE</span>
                </div>
              </div> */}

              <div className="nav-item dropdown">
                {/* <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">Engage */}
                <span href="#" className={active == 'Engage' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Engage
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  {/* <span onClick={() => navigate('/DiscussionForum')} className="dropdown-item py-2 border-bottom cursor-pointer">Discussion
                    forum</span> */}
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Upload Documents</span> */}
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Discussion
                    forum</span> */}
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Events</span> */}
                  <span onClick={() => navigate('/DiscussionForum')} className="dropdown-item py-2 cursor-pointer ">Join Discussion Forum</span>
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 cursor-pointer">Share Idea / Proposal</span> */}
                </div>
              </div>

              <div className="nav-item dropdown">
                <span href="#" className={active == 'Knowledge' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Knowledge
                  Repository
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  {/* <span onClick={() => navigate('/reports')}
                    className="dropdown-item border-bottom py-2 border-bottom cursor-pointer">Reports</span> */}
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">White Papers</span> */}
                  <span onClick={() => navigate('/Reports')} className="dropdown-item py-2 border-bottom cursor-pointer">Reports</span>
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Policy Briefs</span> */}
                  <span onClick={() => navigate('/casestudy')} className="dropdown-item  py-2 cursor-pointer">Case
                    Study</span>
                  {/* <!-- <span href="testimonial.html" className="dropdown-item py-2">Upload</span> --> */}
                </div>
              </div>


              <div className="nav-item dropdown">
                <span href="#" className={active == 'Events' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Events
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate(`/events?id=1`)} className="dropdown-item py-2 border-bottom cursor-pointer">Workshop</span>
                  <span onClick={() => navigate(`/events?id=2`)} className="dropdown-item py-2 cursor-pointer ">Webinars </span>
                  {/* <span onClick={() => navigate('/events')} className="dropdown-item py-2 cursor-pointer">Events</span> */}

                </div>
              </div>

              <div className="nav-item dropdown">
                <span href="#" className={active == 'Multimedia' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}
                  data-bs-toggle="dropdown">Multimedia
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate('/photos?name=Photo')} className="dropdown-item py-2 border-bottom cursor-pointer">Photos</span>
                  <span onClick={() => navigate('/videos?name=Video')} className="dropdown-item py-2  cursor-pointer border-bottom">Videos</span>
                  {/* <span onClick={() => navigate('/News')} className="dropdown-item py-2 cursor-pointer border-bottom">In News</span> */}
                  <span onClick={() => navigate('/News')} className="dropdown-item py-2 cursor-pointer">Press Release</span>

                </div>
              </div>

              {/* <div className="nav-item dropdown">
                <span href="#" className={active == 'Contact' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Contact
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 cursor-pointer border-bottom">Chat Support</span>
                  <span onClick={() => navigate('/contactus')} className="dropdown-item py-2 cursor-pointer">Admin</span>
                </div>
              </div> */}
              <span onClick={() => navigate('/contactus')}
                className={active == 'Contact' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>Contacts
              </span>

              <span onClick={() => navigate('/faq')}
                className={active == 'FAQ' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>FAQ
              </span>

              {/* <span onClick={() => navigate('/contactus')}
                className={active == 'Contact' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>Contact Us
              </span> */}



            </div>
          </div>

        </nav>
      </div>

      {
        showRegistration &&
        <RegistrationPopUp
          handleShowRegistration={handleShowRegistration}
        />
      }

      {
        showPledge &&
        <PledgeRegistration
          handleShowPledge={handleShowPledge}
        />
      }

    </div>
    // </div>
  )
}
