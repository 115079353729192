
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { PartnerNameDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'
import { AstricSign } from '../../../Helper/AstricSign/AstricSign'


export const PartnerNameDDL = (props) => {
    const { partnerNameDDL, setpartnerNameDDL, apiFlag, rowData, PartnerTypeID, Astric } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_PartnerTypeID: PartnerTypeID
        }
        dispatch(PartnerNameDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.PartnerNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_PartnerRegistrationID,
                label: item.m_PartnerName,
            }))
            RoleID == 2 ?
                setpartnerNameDDL({
                    DDL: list,
                    ID: list[0].value,
                    Label: list[0].label,
                })
                :
                setpartnerNameDDL({
                    DDL: list,
                    ID: apiFlag == 'Update' ? rowData?.m_PartnerRegistrationID : 0,
                    Label: apiFlag == 'Update' ? rowData?.m_PartnerName : "Select...",

                })
        }
        else {

            setpartnerNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Partner Name
                {/* {Astric && <AstricSign/>}  */}
            </label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: partnerNameDDL.ID, label: partnerNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setpartnerNameDDL({ ...partnerNameDDL, ID: e.value, Label: e.label })
                        :
                        setpartnerNameDDL({ ...partnerNameDDL, ID: 0, Label: "Select..." })

                }}
                options={partnerNameDDL.DDL}
            />
        </div>
    )
}