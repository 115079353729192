
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const HeroSection = () => {
    return (
        <div className="row g-0" id="herosection">
            <div className="col-12">
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators ">
                        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                            className="active btn_carousal" aria-current="true" aria-label="Slide 1"></button> */}
                        {/* <button type="button" className="btn_carousal" data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="1" aria-label="Slide 2"></button> */}
                        {/* <button type="button" className="btn_carousal" data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                    </div>
                    <div className="carousel-inner active carousalHeight">

                        {/* <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/modiheronew.jpg?updatedAt=1701510156510" alt="About" className="d-block w-100 hero_carousal " width='100vw'/> */}
                        {/* <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/without_modi-02.jpg?updatedAt=1710758577643" alt="About" className="d-block w-100 hero_carousal " width='100vw'/> */}
                        <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/ggci%20banner-01.jpg?updatedAt=1735292638512" alt="About" className="d-block w-100 hero_carousal " width='100vw'/>

                        {/* <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/gplanet.png?updatedAt=1733203329533" alt="About" width="100%" height='100%'/> */}

                        {/* <img src="assets/img/modiheronew.jpg" className="d-block w-100 hero_carousal "
                            alt="..." /> */}
                        {/* <div className="carousel-item active d-flex justify-content-center">
                           
                            <video className="d-block hero_carousal" loop autoPlay muted controls>
                                <source src="assets/video/LiFE.mp4" type="video/mp4" />
                            </video>
                        </div> */}
                        <div className="carousel-item carousalWidth">
                            {/* <img src="assets/img/gplanet_word-01.png" className="d-block w-100 hero_carousal " style={{ position: 'abl' }}
                                alt="..." />
                            <video className="d-block w-100 hero_carousal" loop autoPlay muted >
                                <source src="assets/video/4163.mp4" type="video/mp4" />
                            </video> */}


                        </div>
                        <div class="carousel-item  carousalWidth">
                            {/* <video className="d-block w-100 hero_carousal" loop autoPlay muted >
                                <source src="assets/video/4163.mp4" type="video/mp4" />
                            </video> */}
                            {/* <img src="assets/img/modis-01.jpg" className="d-block w-100 hero_carousal "
                                alt="..." /> */}
                            <div class="carousel-caption d-md-block">
                                {/* <img src="assets/img/gplanet_word-01.png" className="d-block hero_carousal d-flex justify-content-center" style={{ marginTop:'-18vw', width:'30vw', marginLeft:'22vw' }}
                                    alt="..." /> */}
                                {/* <span className="text-center" style={{ marginTop: '-88vw', fontSize: '5vw', color: 'white', fontWeight: 'bolder', fontFamily: 'Montserrat' }}>G-PlaNET</span>
                                <h2 style={{ marginTop: '7vw' }}></h2> */}
                            </div>
                        </div>
                        {/* <div className="carousel-item"> */}
                        {/* <img src="assets/img/herosection/g20newnew-01.jpg" className="d-block w-100 hero_carousal"
                                alt="..." /> */}
                        {/* <video className="d-block w-100 hero_carousal" loop autoPlay muted controls>
                                <source src="assets/video/LiFE.mp4" type="video/mp4" />
                            </video> */}
                        {/* </div> */}
                        {/* <div className="carousel-item">
                            <img src="assets/img/herosection/g20newnew-01.jpg" className="d-block w-100 hero_carousal"
                                alt="..." />
                        </div> */}
                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button> */}
                    {/* <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}
                </div>
            </div>
        </div >
    )
}