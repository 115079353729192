import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData';
import { NewsLetterDataAPI } from '../../../../../Redux/MultimediaSlice/MultimediaSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from '../../../../../Helper/Context';
import moment from 'moment';
import { LoginPageModal } from '../../../Event/Modal/LoginPageModal';

const News = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    const [ShowLoginPage, setShowLoginPage] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const data = {
            MediaDate: '',
            Flag: 'WEB',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
        }

        dispatch(NewsLetterDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.NewsLetterData)

    const handleShowLoginPagePage = () => {
        setShowLoginPage(!ShowLoginPage)
    }

    const handlePost = () => {
        setIsPost(!IsPost)
        setShowLoginPage(false)
    }


    const handleReadMoreClick = (reportDoc) => {
        if (UserID == '') {
            setShowLoginPage(true);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
        } else {
            if (reportDoc) {
                window.open(reportDoc, '_blank');
            }
        }
    };

    return (
        <>
            <Header active='Multimedia' />

            <PagesHeroSection
                Heading='Press Release'
                BreadScrum1='Home'
                BreadScrum2='Multimedia'
            />

            <div className="row px-3 px-lg-5 py-4">
                {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (
                            // <div class="col-12 col-md-6 px-4 mt-1 px-1 px-lg-4">
                            //     <div class="pt-4">
                            //         <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '25px' }} className="py-2" />
                            //         <span class="text-dark ps-2"> {i?.subject}</span>

                            //         <p class="py-3 font_size fw-lighter border-bottom pb-3">
                            //             <LazyLoadImage effect='blur' src="assets/img/calendar.png" alt="About" style={{ width: '15px' }} className="me-2" />
                            //             <span>{i.mediaDate ? moment(i.mediaDate).format('MMMM DD YYYY') : ''}</span>
                            //             {/* <a href={i?.newsDoc} target='_blank' class="float-end text-success fs-6">Read More... <i
                            //                 class="fa-solid fa-right-to-bracket ps-2"></i></a> */}

                            //             <span
                            //                 onClick={() => handleReadMoreClick(i?.newsDoc)}
                            //                 className="float-end text-success fs-6 cursor-pointer">
                            //                 Download{' '}
                            //                 <i className="fa-solid fa-download ps-2"></i>
                            //             </span>

                            //         </p>

                            //     </div>
                            // </div>
                            <div className="col-12 col-md-4 col-lg-3 mt-4 " >
                                <div className="shadow_media rounded border  gray cursor-pointer bg-white" >

                                    <LazyLoadImage
                                        effect='blur'
                                        src={i?.newsDoc ? i.newsDoc.split('.').pop() == 'pdf' ? "https://ik.imagekit.io/gplanet/Gplanet/img/pdf.jpg?updatedAt=1735631767767" : i.newsDoc : null}
                                        alt="Press Release"
                                        width='100%'
                                        height='200px'
                                        style={{ objectFit: 'contain' }}
                                        onClick={() => window.open(i?.newsDoc)}
                                    />

                                    <div className="  px-3 mt-2 text-start text-dark fw-bold" style={{ fontSize: '13px', height: '5vh' }} >
                                        {i?.subject}
                                    </div>
                                    <div className=" border-success  py-3 px-3" style={{ fontSize: '13px', height: '8vh' }}>
                                        <strong>URL :</strong>
                                        <a href={i?.urlPath} target="_blank" rel="noopener noreferrer" className='ps-2'>
                                            {i?.urlPath ? i?.urlPath : '-'}
                                        </a>
                                    </div>
                                </div>


                            </div>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                }

                {/* <div class="row">
                    <div className='col-12 col-md-4 mx-auto text-center '>
                        <NoData />
                    </div>
                </div> */}

            </div>
            <Footer />

            {
                ShowLoginPage && <LoginPageModal
                    handleShowLoginPagePage={handleShowLoginPagePage}
                    handlePost={handlePost}
                />
            }
        </>
    )
}

export default News