import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout, useAuthState } from '../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { RegistrationPopUp } from '../../Pages/PopUp/RegistrationPopUp'
import { PopUpProfileScreen } from './PopUpProfileScreen'

export default function AdminHeader({ setYearValue, setMonthValue }) {

    const dispatch = useDispatch()
    const userDetails = useAuthState()
    const { username, UserID, token,RoleID } = userDetails

    const navigate = useNavigate()

    const LogOut = () => {
        logout(dispatch)
        navigate('/')
    }

    function toggle() {
        let side = document.getElementById("sidebar")
        side.classList.toggle("active")
    }
    const [showRegistration, setshowRegistration] = useState(false)

    const handleShowRegistration = () => {
        setshowRegistration(!showRegistration)
    }

    return (
        // <div className="container-fluid g-0">
        <div className="row bg-white shadow g-0 nav_position adminheader" id="top_logos" onLoad={() => window.scrollTo(0, 0)}>
            <div className="col-12 top_nav d-flex justify-content-between align-items-center mx-auto bg-white py-2">

                <span className="ps-3">

                    <span className='ms-4 hamburger pc_none'
                        onClick={toggle}
                    >
                        <i className="fa-solid fa-bars fa-lg"></i>
                    </span>

                    <a className="navbar-brand g_logo px-3 ">
                        <img src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475" alt="" className="top_logos" style={{ width: "100px" }} />
                    </a>
                    <img src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos pc_none" style={{ width: "30px" }} />

                    <span className='ms-4 hamburger mobile_none'
                        onClick={toggle}
                    >
                        <i className="fa-solid fa-bars fa-lg"></i>
                    </span>

                    <span className='ps-4'>
                        <a className="text-decoration-none lightgreencolor fw-lighter mobile_none fs-4 header_font">
                            Global Green Credit Initiative
                        </a>
                    </span>
                </span>

                {/* <span>

                    <span href="#" className="text-dark pc_none" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">Registration</span>


                </span> */}


                {/* <span>
                    <span className="text-dark  pe-2 pc_none"><i className="fa-solid fa-bars"></i></span>
                </span> */}


                <span className='d-flex align-items-center'>

                    {/* <span href="#" className="text-dark mx-2 mobile_none" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">Registration</span>
                    <span href="login.html" className="text-dark mx-2  pe-2 mobile_none">Login</span> */}
                    {/* <span href="login.html" className="text-dark mx-2  pe-4 mobile_none">User</span> */}
                    {/* <button type="button" className="clear_button mx-4"
                        onClick={() => navigate(`/`)}
                    >  GGCI Portal</button> */}
                    <span type="button" onClick={() => navigate(`/`)} className="text-white mx-2 mobile_none cursor-pointer me-4 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>GGCI Portal</span>
                    <span type="button" onClick={() => navigate(`/DiscussionForum`)} className="text-white mx-2 mobile_none cursor-pointer me-4 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>Discussion Forum</span>

                    <div className="dropdown pe-3">
                        {/* <h6 className=" dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            user
                        </h6> */}

                        <span className="cursor-pointer user-dropdown dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://ik.imagekit.io/gplanet/Gplanet/img/profile.png?updatedAt=1701510156922" className="d-block  hero_carousal"
                                alt="..." style={{ height: '30px', width: '30px' }} />
                        </span>
                        {/* onClick={handleShowRegistration} */}
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item cursor-pointer gray_bg" onClick={(e)=>{RoleID != 1 ? handleShowRegistration() : e.preventDefault()}}><i className="fa-solid fa-user me-2"></i>{username ? username : ''}</a></li>
                            {/* <li><a className="dropdown-item cursor-pointer"><i className="fa-solid fa-key me-2"></i>Change Password</a></li> */}
                            <li><a className="dropdown-item cursor-pointer" onClick={LogOut}><i className="fa-solid fa-power-off me-2"></i>Log Out</a></li>
                        </ul>
                    </div>
                    {/* <span className="px-2 mx-3 search_span mobile_none">

                        <input className=" px-2 py-1 search_input" type="search" id="site-search" placeholder="Search"
                            name="q" />
                        <span className="icon_top search_icon"><i className="fa-solid fa-magnifying-glass "></i></span>
                    </span> */}


                    <a className="navbar-brand ps-3 g_logo1 mobile_none">
                        {/* assets/img/right_logo.png */}
                        <img src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos mobile_none" style={{ width: "30px" }} />
                    </a>
                </span>
            </div>



            {
                showRegistration &&
                <PopUpProfileScreen
                    handleShowRegistration={handleShowRegistration}
                />
            }

        </div>
        // </div>
    )
}
