import { LazyLoadImage } from "react-lazy-load-image-component"
import Header from "../../../Components/Header/Header"
import Footer from "../../../Components/Footer/Footer"
import { HomeUpcomingEvents } from "../DashboardPages/Home/HomeUpcomingEvents/HomeUpcomingEvents"
import { HomePastEvent } from "../DashboardPages/Home/HomePastEvent/HomePastEvent"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"
import { useSearchParams } from "react-router-dom"

export const Events = () => {

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    return (
        <div class="container-fluid g-0">
            <Header />
            
            <PagesHeroSection
                Heading={id == '1' ? 'Workshop' : 'Webinars'}
                BreadScrum1='Home'
                BreadScrum2='Events'
            />

            <HomeUpcomingEvents />

            <HomePastEvent />

            <Footer />
        </div>
    )
}