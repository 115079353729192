
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";

// Get Table Data
export const DiscussionForumTableDataAPI = createAsyncThunk("DiscussionForumTableData", async ({ data }) => {
    const {
        M_DiscussionForumID,
        M_UserID,
        token,
        Flag,
        Top,
        setDiscussionForumData,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_DiscussionForum_Select?M_DiscussionForumID=${M_DiscussionForumID}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data_Details) {
                setDiscussionForumData && setDiscussionForumData(result.data_Details)
                setTotalCount && setTotalCount(result?.data_Details[0]?.totalCount)
                return result.data_Details
            } else {
                setDiscussionForumData && setDiscussionForumData(result)
                setTotalCount && setTotalCount(0)
                return result
            }
        })
})

const DiscussionForumTableDataSlice = createSlice({
    name: "DiscussionForumTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DiscussionForumTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DiscussionForumTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DiscussionForumTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DiscussionForumTableDataReducer = DiscussionForumTableDataSlice.reducer

// InsertUpdate Table Data

export const DiscussionForumPostAPI = createAsyncThunk("DiscussionForumPost", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        Subject,
        DocumentPath,
        Document_FileExtension,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setisLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("Subject", Subject);
    formdata.append("DocumentPath", DocumentPath);
    formdata.append("Document_FileExtension", Document_FileExtension);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_DiscussionForum_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setisLoading(false)
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setisLoading(false)
            return result

        })
})

const DiscussionForumPostSlice = createSlice({
    name: "DiscussionForumPost",
    initialState: {
        isPostLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DiscussionForumPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(DiscussionForumPostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(DiscussionForumPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DiscussionForumPostReducer = DiscussionForumPostSlice.reducer



export const DiscussionForumCommentInsertPostAPI = createAsyncThunk("DiscussionForumCommentInsert", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_DiscussionForumID,
        Comments,
        M_UserID,
        token,
        handleIsPost,
        setIsLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("M_DiscussionForumID", M_DiscussionForumID);
    formdata.append("Comment", Comments);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_DiscussionForum_Comment_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsLoading && setIsLoading(false)
                handleIsPost()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setIsLoading && setIsLoading(false)
            return result

        })
})

const DiscussionForumCommentInsertSlice = createSlice({
    name: "DiscussionForumCommentInsert",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DiscussionForumCommentInsertPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DiscussionForumCommentInsertPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(DiscussionForumCommentInsertPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DiscussionForumCommentInsertReducer = DiscussionForumCommentInsertSlice.reducer





//LikeDislike Api

export const Comment_LikeDisLike_InsertPostAPI = createAsyncThunk("Comment_LikeDisLike_Insert", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_DiscussionForumID,
        M_DiscussionForum_CommentID,
        IsLike,
        IsDisLike,
        M_UserID,
        token,
        handleIsPost,
        setIsLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("M_DiscussionForumID", M_DiscussionForumID);
    formdata.append("M_DiscussionForum_CommentID", M_DiscussionForum_CommentID);
    formdata.append("IsLike", IsLike);
    formdata.append("IsDisLike", IsDisLike);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_DiscussionForum_Comment_LikeDisLike_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsLoading && setIsLoading(false)
                handleIsPost()
                // handleClear()
                // toastSuccesss(result.message)
            } else {
                toastErrorr('Something went wrong!')
            }
            setIsLoading && setIsLoading(false)
            return result

        })
})

const Comment_LikeDisLike_InsertSlice = createSlice({
    name: "Comment_LikeDisLike_Insert",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(Comment_LikeDisLike_InsertPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(Comment_LikeDisLike_InsertPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(Comment_LikeDisLike_InsertPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const Comment_LikeDisLike_InsertReducer = Comment_LikeDisLike_InsertSlice.reducer

//Delete Api

export const DiscussionForumDeleteAPI = createAsyncThunk("DiscussionForumDelete", async ({ data }) => {
    const {
        M_DiscussionForumID,
        M_UserID,
        token,
        handleIsPost,
        handlePublishCloseClick,
        setIsDeleteLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DiscussionForumID", M_DiscussionForumID);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_DiscussionForum_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setIsDeleteLoading(false)
                handleIsPost()
                handlePublishCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setIsDeleteLoading(false)
            return result

        })
})

const DiscussionForumDeleteSlice = createSlice({
    name: "DiscussionForumDelete",
    initialState: {
        isDeleteLoading: false,
        DeletetableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DiscussionForumDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DiscussionForumDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.DeletetableData = action.payload;
        });
        builder.addCase(DiscussionForumDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.DeletetableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DiscussionForumDeleteReducer = DiscussionForumDeleteSlice.reducer

//Delete Api

export const DiscussionForumCommentDeleteAPI = createAsyncThunk("DiscussionForumCommentDelete", async ({ data }) => {
    const {
        M_DiscussionForum_CommentID,
        M_UserID,
        token,
        handleIsPost,
        handlePublishCloseClick,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DiscussionForum_CommentID", M_DiscussionForum_CommentID);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };


    fetch(`${BaseUrl}/Master/Post_WEB_M_DiscussionForum_Comment_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                setloading(false)
                handleIsPost()
                handlePublishCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(false)
            return result

        })
})

const DiscussionForumCommentDeleteSlice = createSlice({
    name: "DiscussionForumCommentDelete",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DiscussionForumCommentDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DiscussionForumCommentDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(DiscussionForumCommentDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DiscussionForumCommentDeleteReducer = DiscussionForumCommentDeleteSlice.reducer






