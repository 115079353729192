import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../Components/Sidebar/Sidebar'
import { useSearchParams } from 'react-router-dom'
import { OrganiztionTypeDDL } from '../../../../Components/DDL/OrganiztionTypeDDL/OrganiztionTypeDDL'
import { StatusDDL } from '../../../../Components/DDL/StatusDDL/StatusDDL'
import { useAuthState } from '../../../../Helper/Context'
import { PartenerApprovalPostAPI, PartenerApprovalTableDataAPI } from '../../../../Redux/AdminSlice/PartenerApprovalSlice/PartenerApprovalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ApprovalPartnerPopUp } from './PartnerApprovalPopUp/ApprovalPartnerPopUp'
import { PostSendEmailAPI } from '../../../../Redux/SendEmailSlice/SendEmailSlice'
import { Pegination } from '../../../../Components/Pagination/Pagination'
import { LogoLoader } from '../../../../Helper/LogoLoader'
import { UserTypeDDL } from '../../../../Components/DDL/UserTypeDDL/UserTypeDDL'
import { KYCFromViewModal } from './PartnerApprovalPopUp/KYCFromViewModal'


const Admin = () => {
    const [params] = useSearchParams()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const username = params.get('username');

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [ApprovalPopUp, setApprovalPopUp] = useState(false)
    const [ActionId, setActionId] = useState('')
    const [isARLoading, setisARLoading] = useState(false)
    const [Heading, setHeading] = useState('')

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [organizationTypeDDL, setorganizationTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [statusDDL, setstatusDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [CountryTypeDDL, setCountryTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [CountryDDL, setCountryDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [UserTypeID, setUserTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const options = [
        { id: 1, value: '', label: '1' },
        { id: 2, value: '', label: '2' },
    ]
    const handleClear = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setpartnerTypeDDL({
            ...partnerTypeDDL,
            ID: 0,
            Label: "Select...",
        })
        setorganizationTypeDDL({
            ...organizationTypeDDL,
            ID: 0,
            Label: "Select...",
        })
        setstatusDDL({
            ...statusDDL,
            ID: 0,
            Label: "Select...",
        })
        setCountryDDL({
            ...CountryDDL,
            ID: 0,
            Label: "Select...",
        })
        setUserTypeID({
            ...UserTypeID,
            ID: 0,
            Label: 'Select...'
        })
    }
    const addButtonClick = (item, heading, id) => {
        setApprovalPopUp(true)
        setEditData(item)
        setActionId(id)
        setHeading(heading)
    }
    const handleAddCloseClick = () => {
        setApprovalPopUp(false)
    }
    const handlePost = () => {
        setIsPost(!IsPost)
    }

    // KYC From

    const [KYCFromShow, setKYCFromShow] = useState(false)
    const [GridData, setGridData] = useState([])

    const handleKYCShowModal = (data) => {
        setKYCFromShow(!KYCFromShow)
        setGridData(data)
    }

    // console.log("KYCFromShow", KYCFromShow)

    useEffect(() => {
        const data = {
            M_FinancialYearID: 0,
            M_MonthID: 0,
            UserType_M_IndicatorID: UserTypeID.ID,
            M_StatusID: statusDDL.ID,
            // M_CountryID: CountryDDL.ID,
            // M_StatusID: statusDDL.ID,
            M_UserID: UserID,
            From: From,
            To: To,
            token: token,
            setTotalCount: setTotalCount
        }
        dispatch(PartenerApprovalTableDataAPI({ data }))
    }, [IsPost, IsClear, partnerTypeDDL.ID, statusDDL.ID, CountryDDL.ID, To, UserTypeID.ID])

    const { tableData, isLoading } = useSelector(state => state.PartenerApprovalTableData)

    const handleApproved = () => {

        const data = {
            m_PartnerRegistrationID: editData?.m_RegistrationID,
            ActionID: ActionId,
            M_UserID: UserID,
            token: token,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick,
            handleSendEmail,
            setisARLoading: setisARLoading
        }
        dispatch(PartenerApprovalPostAPI({ data }))

    }

    // const {isARLoading} = useSelector(state=>state.PartenerApprovalPostData)

    const handleSendEmail = () => {
        const data = {
            Full_Name: editData.g_Planet_text,
            Email_ID: editData.email_ID,
            Username: editData.username,
            Password: editData.password,
            Flag: ActionId == '1' ? 'Approved' : 'Rejected'
        }
        dispatch(PostSendEmailAPI({ data }))
    }

    return (
        <>
            {isLoading && <LogoLoader />}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div className="row flex-nowrap gr wrapper">
                <Sidebar active='PartnerApproval' />
                <div className="col py-3 text-dark wrapper" id='content'>
                    <div className="page-content">
                        <h5 className=" fw-bold my-3 "> User Approval
                            {/* <!-- <span className="float-end"><a href="#n_popup_login"
                                className="add_btn  mx-1 green_color py-0">Add</a></span> --> */}
                        </h5>
                        <div className="row py-4 card p-2">
                            <div className="col-12">

                                <div className="col bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">


                                        <div className="col-12 col-md-6 col-lg-3">
                                            <UserTypeDDL
                                                UserTypeID={UserTypeID}
                                                setUserTypeID={setUserTypeID}
                                                ApiFlag="UserType"
                                            />


                                            {/* <UpdatedCountryDDL
                                                CountryDDL={CountryDDL}
                                                setCountryDDL={setCountryDDL}
                                                Flag="PartnerRegistration"
                                            /> */}


                                        </div>
                                        {/* <div className="col-12 col-md-6 col-lg-3">
                                            <PartnerTypeDDL
                                                partnerTypeDDL={partnerTypeDDL}
                                                setpartnerTypeDDL={setpartnerTypeDDL}
                                                lable={true}
                                                // Label={'Partner Type' ? 'Partner Type' : 'Participants Type'}
                                                Label='Partner Type'
                                            />
                                        </div> */}
                                        {
                                            partnerTypeDDL.ID == 2 || partnerTypeDDL.ID == 3 ?

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <OrganiztionTypeDDL
                                                        organizationTypeDDL={organizationTypeDDL}
                                                        setorganizationTypeDDL={setorganizationTypeDDL}
                                                        PartnerTypeID={partnerTypeDDL.ID}
                                                        Label={partnerTypeDDL.ID == 2 ? 'Organization Type' : 'Academia & Researchers'}
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }

                                        {/* {
                                            partnerTypeDDL.ID == 3 ?
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <OrganiztionTypeDDL
                                                        organizationTypeDDL={academiaResearchersDDL}
                                                        setorganizationTypeDDL={setacademiaResearchersDDL}
                                                        PartnerTypeID={partnerTypeDDL.ID}
                                                        Label='Academia & Researchers'
                                                    />
                                                </div>
                                                :
                                                <></>
                                        } */}


                                        <div className="col-12 col-md-6 col-lg-3">
                                            <StatusDDL
                                                statusDDL={statusDDL}
                                                setstatusDDL={setstatusDDL}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            > <i class="fa-solid fa-xmark pe-1"></i> Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>
                                    </div>

                                </div>


                                <div className="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    {/* <!-- <h3 className="lightgreencolor pb-3">Participants</h3> --> */}
                                    <table>
                                        <thead>
                                            <tr className="header lightgreenbg whitecolor">
                                                <th>Sr. No.</th>
                                                {/* <th>Country Name</th>
                                                <th>Partner Type</th>
                                                <th>Sub Partner Type</th> */}
                                                {/* <th>Country/Region</th> */}
                                                <th>Full Name </th>
                                                <th>Organization Name</th>
                                                <th>Designation </th>
                                                <th>Email ID</th>
                                                <th>Phone Number</th>
                                                <th>User Type</th>
                                                <th>KYC From </th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className='text-center' width='6%'>{item.rowNum}</td>
                                                        {/* <td>{item.countryName ? item.countryName : '-'}</td>
                                                        <td>{item.partnerTypeName ? item.partnerTypeName : '-'}</td>
                                                        <td>{item.subPartnerTypeName ? item.subPartnerTypeName : '-'}</td> */}
                                                        {/* <td>{item.countryName ? item.countryName : '-'}</td> */}
                                                        <td>{item.firstName ? item.firstName : '-'}</td>
                                                        <td>{item.organizationName ? item.organizationName : '-'}</td>
                                                        <td>{item.designationName ? item.designationName : '-'}</td>
                                                        <td>{item.email_ID ? item.email_ID : '-'}</td>
                                                        <td>{item.phoneNumber ? item.phoneNumber : '-'}</td>
                                                        <td>{item.userType ? item.userType : '-'}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <span className='p-1 text-white cursor-pointer' style={{ background: "#4f4d4d", borderRadius: '5px' }}
                                                                onClick={() => handleKYCShowModal(item)}>View</span>
                                                        </td>
                                                        <td className={`status-color-${item.m_StatusID}`}>{item.m_Status ? item.m_Status : '-'}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                item.m_StatusID == '1' ?
                                                                    <span style={{ cursor: 'pointer' }}
                                                                        onClick={() => addButtonClick(item, 'Reject PopUp', '2')}
                                                                    >
                                                                        <i className="fs-6 fa-solid fa-circle-xmark fa-lg" title='Reject' style={{ color: "red" }}></i>
                                                                    </span>
                                                                    :
                                                                    item.m_StatusID == '2' ?
                                                                        <span className='' style={{ cursor: 'pointer' }}
                                                                            onClick={() => addButtonClick(item, 'Approval PopUp', '1')}
                                                                        >
                                                                            <i className="fs-6 fa-solid fa-circle-check fa-lg" title='Approve' style={{ color: "green" }}></i>
                                                                        </span>
                                                                        :
                                                                        <>
                                                                            <span className='' style={{ cursor: 'pointer' }}
                                                                                onClick={() => addButtonClick(item, 'Approval PopUp', '1')}
                                                                            >
                                                                                <i className="fs-6 fa-solid fa-circle-check fa-lg" title='Approved' style={{ color: "green" }}></i>
                                                                            </span>

                                                                            <span className='px-1'></span>


                                                                            <span style={{ cursor: 'pointer' }}
                                                                                onClick={() => addButtonClick(item, 'Reject PopUp', '2')}
                                                                            >
                                                                                <i className="fs-6 fa-solid fa-circle-xmark fa-lg" title='Rejected' style={{ color: "red" }}></i>
                                                                            </span>
                                                                        </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )) : <tr>No data</tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {tableData && tableData.table && tableData.table.length > 0 &&
                                    <Pegination
                                        PerPageCount={PerPageCount}
                                        TotalCount={tableData.table[0].totalCount}
                                        setFrom={setFrom}
                                        setTo={setTo}
                                        setrowNo={setrowNo}
                                        CurrentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ApprovalPopUp && <ApprovalPartnerPopUp
                        open={ApprovalPopUp}
                        heading={Heading}
                        handleAddCloseClick={handleAddCloseClick}
                        handleApproved={handleApproved}
                        isARLoading={isARLoading}
                    />
                }
                {KYCFromShow &&
                    <KYCFromViewModal
                        KYCFromShow={KYCFromShow}
                        handleKYCShowModal={handleKYCShowModal}
                        GridData={GridData} />
                }
            </div>
        </>
    )
}

export default Admin