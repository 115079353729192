import { PartnerTypeDDL } from "../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import React, { useState } from 'react'

export const AddNewsAdmin = (props) => {
    const { handleOnAddClick } = props

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor" id="exampleModalLabel">ADD NewsLetter
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div className="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6 col-lg-6" style={{marginTop:"0.8em"}}>
                                    <PartnerTypeDDL
                                        partnerTypeDDL={partnerTypeDDL}
                                        setpartnerTypeDDL={setpartnerTypeDDL}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Date
                                    </label>
                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">PDF
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Photo
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Video
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">URL
                                    </label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>

                            <p className="float-end py-5">
                                <button type="button" className="btn  lightgreenbg text-white px-4">Upload </button>
                            </p>


                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}