

import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { EventTypeDDLAPI } from '../../../Redux/DDLSlice'
import { AstricSign } from '../../../Helper/AstricSign/AstricSign'


export const EventTypeDDL = (props) => {
    const { eventTypeDDL, seteventTypeDDL, apiFlag, rowData, IsPost,Astric,Flag } = props

    const dispatch = useDispatch()

    const UserID = sessionStorage.getItem('User')
    const access_token = sessionStorage.getItem('NMCToken')

    useEffect(() => {
        const data = {
            userID: UserID,
            access_token: access_token
        }
        dispatch(EventTypeDDLAPI({ data }))
    }, [IsPost])

    const { DDLData } = useSelector(state => state.EventTypeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_EventTypeID,
                label: item.eventType,
            }))

            seteventTypeDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_EventTypeID : 0,
                Label: apiFlag == 'Update' ? rowData?.eventType : "Select...",

            })
        }
        else {
            seteventTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Event Type{Astric && <AstricSign/>}</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: eventTypeDDL.ID, label: eventTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        seteventTypeDDL({ ...eventTypeDDL, ID: e.value, Label: e.label })
                        :
                        seteventTypeDDL({ ...eventTypeDDL, ID: 0, Label: "Select..." })

                }}
                options={eventTypeDDL.DDL}
            />
        </div>
    )
}