import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context"
import { PhotovideoEventTableDataAPI } from "../../../../../Redux/MultimediaSlice/MultimediaSlice"
import { LogoLoader } from "../../../../../Helper/LogoLoader"
import { NoData } from "../../../../../Helper/NoData/NoData";
import { EventData } from "../../../../../Helper/DummyData";

export const HomeUpcomingEvents = () => {

    const navigate = useNavigate()
    const [GCPUpcoming, setGCPUpcoming] = useState('')

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails



    useEffect(() => {
        setGCPUpcoming([])
        const data = {
            M_EventID: '0',
            Flag: id == 1 ? 'Workshop_UpcomingEvent ' : 'Webinar_UpcomingEvent ',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
            name: 'UpcomingEvent',
            setGCPUpcoming: setGCPUpcoming
        }
        dispatch(PhotovideoEventTableDataAPI({ data }))
    }, [id])

    const { isLoading, tableData } = useSelector(state => state.PhotovideoEventTableData)

    console.log("GCPUpcoming", GCPUpcoming)

    // const options = {
    //     500: {
    //         items: tableData && tableData.length > 3 ? 4 : tableData.length
    //     }
    // }

    return (
        <>
            {isLoading && <LogoLoader />}

            <div className="row upcomming px-lg-5 pb-lg-2 p-3  ">
                <div className="row d-flex align-items-center text-white lightgreenbg border rounded ps-4 py-2">
                    <div className="col-12 col-lg-6 " >
                        <h5 className="cpitalize text-white" style={{ fontWeight: 'normal' }}> Upcoming Activities & Events </h5>
                    </div>
                </div>
            </div>

            {/* {
                // showGCPLaunch ?
                <GCPLaunch tableData={GCPUpcoming} />
                // :
                // <COP28 />
            } */}

            <div className="row mx-5 event_height">
                <>
                    {
                        GCPUpcoming && GCPUpcoming.length > 0 ? GCPUpcoming.map(i => {
                            return (
                                <div className='col-12 col-md-4 col-lg-3 item px-lg-3 pt-lg-4'>
                                    <div className="shadow_media rounded border gray cursor-pointer bg-white" >
                                        <LazyLoadImage
                                            effect='blur'
                                            src={i?.eventPhoto != null ? i?.eventPhoto : "assets/img/event/no_img-01.jpg"}
                                            alt="About"
                                            width='100%'
                                            height='200px'
                                            onClick={() => window.open(i.eventPhoto)}
                                            onError={(e) => {
                                                e.target.src = "https://ik.imagekit.io/gplanet/Gplanet/img/404-01.png?updatedAt=1701510153687"; // Fallback to 404 image if error
                                            }}
                                        />
                                        <div className="  px-3 mt-3 text-start fw-bold lightgreencolor" style={{ fontSize: '14px', height: '5vh' }} >
                                            {i?.eventSubject}
                                            {/* <span className="lightgreencolor"  >More...</span> */}
                                        </div>
                                        <div className=" border-success  px-3" style={{ fontSize: '13px', height: '5vh' }}>
                                            {/* <span className="lightgreencolor fw-bold">Partner Name</span> */}
                                            <span className="text-dark" ><i class="fa-solid fa-location-dot text-danger me-1"></i> {i?.location}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                            :
                            <div className='col-12 col-md-4 col-lg-3 mx-auto text-center '>
                                <NoData />
                            </div>

                    }
                </>

                {/* <div className="col-12 col-lg-6 ">
                    <span className="float-end cursor-pointer border view_all_btn p-2 rounded ">
                        <a onClick={() => navigate('/Commingsoon')} className="" style={{ fontWeight: 'normal' }}>
                            View All  <i className="fa-solid fa-arrow-right ps-2"></i>
                        </a> </span>
                </div> */}

            </div>

            {/* <div className="row mx-5">
                <>
                    {
                        GCPUpcoming && GCPUpcoming.length > 0 ? GCPUpcoming.map(i => {
                            return (
                                <div className='col-4 item px-lg-3 pt-lg-3'>
                                    <div className="shadow_media rounded border gray cursor-pointer bg-white" >
                                        <img effect='blur' src={i?.eventPhoto != null ? i?.eventPhoto : "assets/img/event/no_img-01.jpg"} alt="About" width='100%' height='200px'
                                            onClick={() => window.open(i.eventPhoto)}
                                        />

                                        <div className="  px-3 mt-3 text-start fw-bold lightgreencolor" style={{ fontSize: '14px', height: '5vh' }} >
                                            {i?.eventSubject}
                                         
                                        </div>
                                        <div className=" border-success  px-3" style={{ fontSize: '13px', height: '5vh' }}>
                                          
                                            <span className="text-dark" ><i class="fa-solid fa-location-dot text-danger me-1"></i> {i?.location}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                            :
                            <div class="col-12 col-lg-12 ps-lg-4 mt-4">
                                <div className='col-12 col-md-4 mx-auto text-center '>
                                    <NoData />
                                </div>
                            </div>
                    }
                </>


                <div className="col-12 col-lg-6 ">
                    <span className="float-end cursor-pointer border view_all_btn p-2 rounded ">
                        <a onClick={() => navigate('/Commingsoon')} className="" style={{ fontWeight: 'normal' }}>
                            View All  <i className="fa-solid fa-arrow-right ps-2"></i>
                        </a> </span>
                </div>

            </div> */}

        </>
    )
}