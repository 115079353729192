import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context"
import { PartenerReportPostAPI } from "../../Redux/AdminSlice/PartnerReportSlice/PartnerReportSlice"
import { PartnerNameDDL } from "../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useEffect, useState } from "react"
import { Loading } from "../../Helper/Loading"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"
import { CommonSamplePDFUploadPostAPI, CommonSampleReportAPIDataAPI } from "../../Redux/AdminSlice/SampleReport/SampleReportSlice"
import { useSearchParams } from "react-router-dom"
import { toastErrorr } from "../../Helper/ToastMessage"
import moment from "moment"
import { SampleReportPopUp } from "./SampleReportViewPopup"
import { convertAfterImageBase64 } from "../../Helper/Base64Converter/Base64ConverterFiles"

export const AddKnowledgeRepositoryReport = (props) => {
    const { handleOnAddClick, handleIsPost, IsPost } = props


    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [params] = useSearchParams()
    const IndicatorName = params.get('IndicatorName');

    const [isPostLoading, setisPostLoading] = useState(false)
    const [Subject, setSubject] = useState('')
    const [ReportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"))
    const [Document, setDocument] = useState(null)
    const [YesConformCheckbox, setYesConformCheckbox] = useState(false)
    const [SamplePDFPostLoading, setSamplePDFPostLoading] = useState(false)
    const [SamplePDFDocument, setSamplePDFDocument] = useState(null)
    const [SampleDocumentType, setSampleDocumentType] = useState(null)

    const [SampleReportViewPopup, setSampleReportViewPopup] = useState(false)

    const handleSampleReportViewPopup = () => {
        if (RoleID == 1) {
            setSampleReportViewPopup(!SampleReportViewPopup)
        } else {
            handleOpenPDF()
        }
    }

    const handleOpenPDF = () => {
        console.log('RoleID', tableData)
        if (tableData && tableData[0]?.sampleReport_Path != null) {
            window.open(tableData[0]?.sampleReport_Path,);
        } else {
            toastErrorr('Sample Report is not available!');
        }
    }

    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)

    const handleYesNo = () => {
        setYesConformCheckbox(!YesConformCheckbox)
    }


    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    // const handleFileInputChange = (e) => {
    //     let file = e.target.files[0];
    //     return new Promise(resolve => {
    //         let baseURL = "";
    //         let reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             baseURL = reader.result;
    //             // console.log(reader.result.split(',')[1])
    //             setDocument(reader.result.split(',')[1])
    //             resolve(baseURL);
    //         };
    //     });
    // };

    const [PDFErrorMessage, setPDFErrorMessage] = useState('')

    const handleFileInputChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) {
                setPDFErrorMessage('File size exceeds 3 MB. Please upload a PDF less than 3 MB.');
                return;
            }

            setPDFErrorMessage('');

            try {
                const base64 = await convertAfterImageBase64(file);
                const type = base64.split(';')[0].split('/')[1];
                setDocument(base64.split(',')[1]);

            } catch (error) {
                console.error('Error converting file to Base64', error);
                setPDFErrorMessage('An error occurred while uploading the file.');
            }
        }
    };

    const handleSamplePDFUPloadFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                const type = baseURL.split(';')[0].split('/')[1];
                setSampleDocumentType('.' + type)
                setSamplePDFDocument(reader.result.split(',')[1])
                resolve(baseURL);
                // console.log('SampleDocumentType',type)
            };
        });
    };


    const handlePost = () => {
        const data = {
            M_PartnerRegistrationID: partnerNameDDL.ID,
            ReportDate: ReportDate,
            ReportSubject: Subject,
            PDF_Doc: Document,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisPostLoading: setisPostLoading
        }
        dispatch(PartenerReportPostAPI({ data }))
    }
    // const { isPostLoading } = useSelector(state => state.PartenerReportPostData)

    useEffect(() => {
        const data = {
            M_SampleReportID: '0',
            SampleReport_M_IndicatorID: IndicatorName ? IndicatorName : '0',
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(CommonSampleReportAPIDataAPI({ data }))
    }, [From, To, IndicatorName, IsPost])

    const { tableData, isLoading } = useSelector(state => state.CommonSampleReportAPIData)

    // console.log('dsfdhfghfdf', tableData && tableData[0]?.sampleReport_Path)

    // const handleSamplePDFPost = () => {
    //     const data = {
    //         M_FinancialYearID: moment().format('YYYY'),
    //         M_MonthID: moment().format('MM'),
    //         SampleReport_M_IndicatorID: IndicatorName,
    //         SampleReport_Path: SamplePDFDocument,
    //         SampleReport_FileExtension: SampleDocumentType,
    //         handleIsPost: handleIsPost,
    //         M_UserID: UserID,
    //         token: token,
    //         setSamplePDFPostLoading: setSamplePDFPostLoading
    //     }
    //     dispatch(CommonSamplePDFUploadPostAPI({ data }))
    // }

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor p-0 m-0" id="exampleModalLabel">Report
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleOnAddClick}> &times; </button>
                    </div>

                    <div class="modal-body px-3 pt-4" >
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <PartnerNameDDL
                                        partnerNameDDL={partnerNameDDL}
                                        setpartnerNameDDL={setpartnerNameDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class=''>Date <AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={ReportDate}
                                        max={moment().format('YYYY-MM-DD')}
                                        onChange={(e) => setReportDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row mt-4'>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Subject <AstricSign />
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={Subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Upload PDF <AstricSign />
                                        <a style={{ fontSize: '13px', textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                            onClick={handleSampleReportViewPopup}>(Click to View Sample Report)</a>
                                    </label>
                                    <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        onChange={(e) => handleFileInputChange(e)}
                                    />
                                    {PDFErrorMessage ?
                                        <div className="row">
                                            <div className="col-12 alert alert-danger mt-3">
                                                <span style={{ fontSize: "14px" }}><strong>Error : </strong> {PDFErrorMessage}</span>
                                            </div>
                                        </div>
                                        :
                                        <small className="text-danger" style={{ fontSize: '12px' }}>Upload a PDF file less than 3 MB</small>

                                    }

                                </div>


                                {
                                    RoleID != 1 &&
                                    <>
                                        <div className="col-12 pt-4">
                                            <p className="">I grant GGCI permission to make the resource I am uploading public on the platform.</p>
                                            <div class="mb-3 form-check">
                                                <input type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck1"
                                                    name="YesConformCheckbox"
                                                    value={YesConformCheckbox}
                                                    onChange={(e) => handleYesNo(e)}
                                                />
                                                <label class="form-check-label " for="exampleCheck1">Yes, I confirm</label>
                                            </div>
                                        </div>

                                    </>
                                }

                                <div className="col-12 pb-3">

                                    <button type="button" class="btn lightgreenbg text-white px-4 float-end"
                                        disabled={
                                            isPostLoading || ReportDate === '' || Subject === '' || Document == null || RoleID != 1 && YesConformCheckbox == false
                                        }
                                        onClick={handlePost}
                                    >
                                        {isPostLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {!isPostLoading && <span> <i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>}
                                    </button>

                                </div>
                                {/* {
                                    RoleID == 1 &&
                                    <>
                                        <div class="col-12 col-md-6 py-2 border-top ">
                                            <label for="exampleInputEmail1" class="form-label pt-1">Sample Report<AstricSign />
                                            </label>
                                            <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" accept=".pdf"
                                                onChange={handleSamplePDFUPloadFileInputChange}
                                            />
                                            <small className="text-danger" style={{ fontSize: '12px' }}>Upload a PDF file less than 3 MB</small>
                                        </div>
                                        <div class="col-12 col-md-6 border-top pt-5 ">
                                            <button class="btn btn-primary text-white fw-200 px-3 fs-6 mx-3" type="submit"
                                                disabled={SamplePDFPostLoading || SamplePDFDocument == null || SamplePDFDocument == ''}
                                                onClick={handleSamplePDFPost}>
                                                {SamplePDFPostLoading && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {!SamplePDFPostLoading && <span><i class="fa-solid fa-floppy-disk pe-1"></i> Save</span>}
                                            </button>
                                        </div>
                                    </>
                                } */}


                            </div>
                        </form>

                    </div>
                    {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                </div>
            </div >
            {SampleReportViewPopup &&
                <SampleReportPopUp
                    SampleReportViewPopup={SampleReportViewPopup}
                    handleSampleReportViewPopup={handleSampleReportViewPopup}
                    handleIsPost={handleIsPost}
                    tableData={tableData}
                    Heading="Sample Report"
                />
            }
        </div >
    )
}