import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PartnerNameDDL } from '../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL'
import FAB from '../../../../../Components/FAB/FAB'
import { AddKnowledgeRepositoryReport } from '../../../../PopUp/AddKnowledgeRepositoryReport'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { PartenerReportDeleteAPI, PartenerReportPatchAPI, PartenerReportTableDataAPI } from '../../../../../Redux/AdminSlice/PartnerReportSlice/PartnerReportSlice'
import moment from 'moment/moment'
import { PublishReportPopUp } from './Action/PublishReportPopUp'
import { Loading } from '../../../../../Helper/Loading'
import { CommonDeletePopUp } from './Action/CommonDeletePopUp'
import { Pegination } from '../../../../../Components/Pagination/Pagination'
import { UpdatedPartnerNameDataDDL } from '../../../../../Components/DDL/PartnerNameDDL/UpdatedPartnerNameDDL'
import { LogoLoader } from '../../../../../Helper/LogoLoader'


export const KnowledgeRepositoryReport = () => {
    const navigate = useNavigate()
    const [showAddPopUp, setshowAddPopUp] = useState(false)

    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    const [params] = useSearchParams()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const username = params.get('username');

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [ApprovalPopUp, setApprovalPopUp] = useState(false)
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [IsDeleteLoading, setIsDeleteLoading] = useState(false)
    const [ActionId, setActionId] = useState('')
    const [UploadDate, setUploadDate] = useState('')

    const [Heading, setHeading] = useState('')

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [UpdatedPartnerNameDDL, setUpdatedPartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })


    const handleClear = () => {
        setUpdatedPartnerNameDDL({
            ...UpdatedPartnerNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setUploadDate('')
    }
    const PublishButtonClick = (item, heading, id) => {
        setPublishPopUp(true)
        setEditData(item)
        // setActionId(id)
        setHeading(heading)
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }

    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }
    const handleDeleteCloseClick = () => {
        setDeletePopUp(false)
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleShowPdf = (PDFUrl) => {
        if (PDFUrl) {
            window.open(PDFUrl)
        }
    }

    useEffect(() => {
        const data = {
            M_KnowledgeRepository_ReportID: '0',
            M_PartnerRegistrationID: UpdatedPartnerNameDDL.ID,
            ReportDate: UploadDate,
            Flag: username == 'admin' ? 'Admin' : 'Partner',
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
            setTotalCount: setTotalCount
        }

        dispatch(PartenerReportTableDataAPI({ data }))
    }, [IsPost, IsClear, UploadDate, To, UpdatedPartnerNameDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.PartenerReportTableData)

    const handlePublish = () => {
        const data = {
            M_KnowledgeRepository_ReportID: editData.m_KnowledgeRepository_ReportID,
            ActionID: '',
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
        }
        dispatch(PartenerReportPatchAPI({ data }))
    }

    const handleDelete = () => {
        setIsDeleteLoading(true)
        const data = {
            M_KnowledgeRepository_ReportID: editData.m_KnowledgeRepository_ReportID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleDeleteCloseClick,
            setIsDeleteLoading: setIsDeleteLoading
        }
        dispatch(PartenerReportDeleteAPI({ data }))
    }


    return (
        <>
            {isLoading && <LogoLoader />}
            <AdminHeader />
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='PartnerReport' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 ">Report</h5>
                        </span>

                        <div class="row py-4  card p-2">
                            <div class="col-12 ">

                                <div class="bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">
                                        {/* {
                                            RoleID == '1' &&
                                            <div class="col-12 col-md-6 col-lg-3"> */}
                                                {/* <PartnerNameDDL
                                                partnerNameDDL={partnerNameDDL}
                                                setpartnerNameDDL={setpartnerNameDDL}
                                            /> */}

                                                {/* <UpdatedPartnerNameDataDDL
                                                    UpdatedPartnerNameDDL={UpdatedPartnerNameDDL}
                                                    setUpdatedPartnerNameDDL={setUpdatedPartnerNameDDL}
                                                    Flag="PartnerReport_Admin"
                                                />
                                            </div>
                                        } */}


                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="AssetName"
                                                    value={UploadDate}
                                                    onChange={(e) => setUploadDate(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-2 col-lg-2 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            > <i class="fa-solid fa-xmark pe-1"></i> Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>
                                        {/* <div class="col-3">
                                            <label for="exampleInputEmail1" class="form-label pt-1">Status
                                            </label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Select</option>
                                                <option value="1"></option>
                                            </select>
                                        </div> */}

                                    </div>
                                </div>

                                <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%', }}>
                                    {/* <!-- <h3 class="lightgreencolor pb-3">Participants</h3> --> */}
                                    <table>
                                        <thead>
                                            <tr class="header lightgreenbg whitecolor">
                                                <th>Sr. No.</th>
                                                <th>Date</th>
                                                <th>Subject</th>
                                                <th>Partner Name</th>
                                                <th style={{ textAlign: 'center' }}>View</th>
                                                <th style={{ textAlign: 'center' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className='text-center' width='6%'>{item.rowNum ? item.rowNum : '-'}</td>
                                                            <td>{item.reportDate ? moment(item.reportDate).format('DD-MM-YYYY') : '-'}</td>
                                                            <td>{item.reportSubject ? item.reportSubject : '-'}</td>
                                                            <td>{item.m_PartnerRegistrationName ? item.m_PartnerRegistrationName : '-'}</td>
                                                         
                                                            <td style={{ textAlign: 'center' }}>
                                                                <span className='' style={{ cursor: 'pointer' }}>
                                                                    <i class="fa-solid fa-file-pdf " title='view' style={{ fontSize: "17px", cursor: "pointer", color: 'red' }}
                                                                        onClick={() => handleShowPdf(item.reportDoc)}
                                                                    ></i>
                                                                </span>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {
                                                                    (RoleID !== '2' && item.statusID != '4') ?
                                                                        <>
                                                                            <span className='' style={{ cursor: 'pointer' }}>
                                                                                <i class="fa-solid fa-arrow-up-from-bracket fs-6" title='Publish'
                                                                                    onClick={() => PublishButtonClick(item, 'Publish PopUp')}
                                                                                ></i>
                                                                            </span>
                                                                            <span className='px-2'></span>
                                                                        </>
                                                                        : <></>
                                                                }
                                                                {
                                                                    (RoleID == '2' && item.statusID == '4') ?
                                                                        <span>-</span>
                                                                        :
                                                                        <span style={{ cursor: 'pointer' }}>
                                                                            <i class="fa-solid fa-trash-can fs-6" title='Delete' style={{ color: 'red' }}
                                                                                onClick={() => handleDeletePopUp(item)}></i>
                                                                        </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    : <>No data</>
                                            }




                                        </tbody>
                                    </table>
                                </div>
                                {tableData && tableData.table && tableData.table.length > 0 &&
                                    <Pegination
                                        PerPageCount={PerPageCount}
                                        TotalCount={tableData.table[0].totalCount}
                                        setFrom={setFrom}
                                        setTo={setTo}
                                        setrowNo={setrowNo}
                                        CurrentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <AddKnowledgeRepositoryReport handleOnAddClick={handleOnAddClick} handleIsPost={handleIsPost} IsPost={IsPost} />
            }

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='publish Report'
                    handleAddCloseClick={handlePublishCloseClick}
                    handlePublish={handlePublish}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='Report'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={IsDeleteLoading}
                />
            }
        </>
    )
}
