import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'

const GPlaNET = () => {
    return (
        <>
            <Header />
            <div>
                <div className="row" id="herosection">
                    <div className="col-12 gplanet_bg_img d-flex  align-items-center">
                        <div className=" py-5 px-4 text-white">
                            <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active "><a href="#" className="text-dark">Home</a></li><span
                                        className="mx-2 text-dark">/</span>
                                    <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                        className="text-dark">About</a></li><span className="mx-2 text-dark">/</span>
                                    <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                        className="lightgreencolor fw-bold">G-PlaNET</a></li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>

                <div class="row p-4 p-md-5  gcp_bg" id="about_gplanet">
                    <div class="col-12 ">

                        <h3 class="text-center text-dark my-3">G-PlaNET is envisaged to have the following purposes</h3>

                        <div>
                            <span class="gcp_about_ul mt-3">
                                <li class="px-2 fs-5 mobile_none"> <i class="ri-arrow-right-double-line lightgreencolor"></i></li>
                                <li class=" justify fw-bold lightgreencolor textsize">
                                    Science
                                </li>
                            </span>
                            <p class="text-dark">
                                <b class="text-dark"> Sharing of global technical know-how and experiences:</b> the science
                                behind activities contributing to environment conservation can be explored and shared (science
                                component)
                            </p>
                        </div>

                        <div>
                            <span class="gcp_about_ul mt-3">
                                <li class="px-2 fs-5 mobile_none"> <i class="ri-arrow-right-double-line lightgreencolor"></i></li>
                                <li class=" justify fw-bold lightgreencolor textsize">

                                    Policy

                                </li>
                            </span>
                            <p class="text-dark">
                                <b class="text-dark"> Shaping green credit policy instruments:</b> Seeks to explore the efficacy
                                of various policy instruments in incentivizing environment actions (policy component)
                            </p>
                        </div>

                        <div>
                            <span class="gcp_about_ul mt-3">
                                <li class="px-2 fs-5 mobile_none"> <i class="ri-arrow-right-double-line lightgreencolor"></i></li>
                                <li class=" justify fw-bold lightgreencolor textsize">

                                    Practice

                                </li>
                            </span>
                            <p class="text-dark">
                                <b class="text-dark fw-bolder"> Supporting implementation:</b> Creating one common value chain
                                connecting communities, self-help groups, farmers/community organizations with
                                companies/corporates and other stakeholders who can participate in the program through 1
                                marketplace (practice component)
                            </p>
                        </div>

                    </div>
                </div>
                {/* <!--end of row about gplanet--> */}

                <div className="row px-4 px-md-5 d-flex align-items-center justify-content-center green_credit_bg"
                    id="Dialogue Series">



                    <h3 className="text-center lightgreencolor mt-5">G-PlaNET comprises several key components</h3>

                    <div className="col-12  col-md-5 credit_img text-center mt-5">
                        <img src="assets/img/gplanet/gplanet_component_1.png" alt="" style={{ width: '60%' }} />
                    </div>
                    <div className="col-12 col-md-7">
                        <h2 className="lightgreencolor">Dialogue Series </h2>
                        <p className="justify">
                            The Dialogue Series invites interested parties, including country governments, international
                            organizations, and think-tanks, to participate in structured dialogues. These dialogues provide a
                            forum for sharing insights, experiences, and ideas related to environmental transformation and
                            sustainability.
                        </p>
                        <p className="justify">
                            The dialogues aim to foster collaboration, identify innovative solutions, and promote best practices
                            for environmental protection and sustainable living.
                        </p>
                    </div>
                </div>
                {/* <!--end of row Dialogue Series--> */}


                <div className="row px-4 px-md-5 d-flex align-items-center justify-content-center green_credit_bg"
                    id="Dialogue Series">
                    <div className="col-12  col-md-7 credit_img pc_none text-center">
                        <img src="assets/img/gplanet/LiFE_Pledge2.jpeg" alt="" style={{ width: '60%' }} />
                    </div>
                    <div className="col-12 col-md-6">
                        <h2 className="lightgreencolor">LiFE Pledge</h2>
                        <p className="justify">
                            Parties are invited to take the LiFE Pledge, a commitment to embrace environmentally conscious
                            lifestyles and actions. The LiFE Pledge not only encourages individuals and organizations to adopt
                            sustainable practices but also enhances the global visibility of Mission LiFE and the Green Credits
                            Programme.
                        </p>

                    </div>

                    <div className="col-12  col-md-5 credit_img text-center mobile_none">
                        <img src="assets/img/gplanet/LiFE_Pledge2.jpeg" alt="" style={{ width: '60%' }} />
                    </div>
                </div>
                {/* <!--end of row Dialogue Series--> */}


                <div className="row p-4 p-md-5 d-flex align-items-center justify-content-center  gcp_bg"
                    id="Dialogue Series">
                    {/* <!-- <div className="col-12  col-md-6 credit_img " >
                    <img src="img/gplanet/gplanet_component_1.png" alt="" style="width: 100%;">
                </div> --> */}
                    <div className="col-12 col-lg-6 ">
                        <h2 className="lightgreencolor text-center mb-5">G-PlaNET Portal</h2>
                        <h6 className=" text-center">The G-PlaNET Portal serves as the digital hub for G-PlaNET activities. It
                            provides various features and resources, including</h6>


                    </div>
                    <div className="col-12 col-lg-6 Participatory_bg">
                        <div>
                            <span className="gcp_about_ul mt-3">
                                <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                                <li className=" justify fw-bold fs-5 text-dark">

                                    Participatory events

                                </li>
                            </span>
                            <p className="text-dark">
                                Stakeholders can express their interest in participating in G-PlaNET activities through the portal.
                            </p>
                        </div>

                        <div>
                            <span className="gcp_about_ul mt-3">
                                <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                                <li className=" justify fw-bold fs-5 text-dark">

                                    Dialogue Calendar

                                </li>
                            </span>
                            <p className="text-dark">
                                The portal hosts a calendar of upcoming dialogues and events.
                            </p>
                        </div>


                        <div>
                            <span className="gcp_about_ul mt-3">
                                <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                                <li className=" justify fw-bold fs-5 text-dark">

                                    Dialogue Reports

                                </li>
                            </span>
                            <p className="text-dark">
                                Reports summarizing the outcomes of dialogues and discussions.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <!--end of row Dialogue Series--> */}

                {/* <!-- Types of GC’s start --> */}
                <div className="row p-4 p-md-5 gc_typesimg" id="Benefits">
                    <h3 className="dark-blue">Benefits of G-PlaNET</h3>
                    <p className="text-dark">The Global Platform for Nature's Ecological Transformation offers a range of benefits for participating countries and stakeholders</p>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center ">
                                <img src="assets/img/gplanet/Global_Leadership-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Global Leadership and Influence</h5>
                                <p className="text-dark pt-2">Participating countries can assert their leadership in environmental stewardship on a global stage, influencing international discussions and commitments.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                                <img src="assets/img/gplanet/Knowledge_Sharing-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Knowledge Sharing</h5>
                                <p className="text-dark pt-2">G-PlaNET facilitates the sharing of knowledge, experiences, and resources, enabling participants to learn from one another and enhance their environmental initiatives.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                                <img src="assets/img/gplanet/Innovative_Solutions-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Innovative Solutions</h5>
                                <p className="text-dark pt-2"> By engaging in structured events and collaboration, countries can identify innovative solutions and policy instruments to promote environmental transformation.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                                <img src="assets/img/gplanet/Collective_Action-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Collective Action</h5>
                                <p className="text-dark pt-2">G-PlaNET fosters opportunities for collective action, enabling countries to work together to address global environmental challenges effectively.</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- end of row Benefits--> */}
            </div >
            <Footer />
        </>
    )
}

export default GPlaNET