
export const ApprovalPartnerPopUp = (props) => {
    const { handleAddCloseClick, heading, handleApproved, isARLoading } = props

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '45%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">{heading}
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleAddCloseClick}> &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                {
                                    heading == 'Approval PopUp' ?
                                        <div className="d-flex justify-content-center mt-4 "> Do you want to Approve? </div>
                                        :
                                        heading == 'Acknowledge' ?
                                            <div className="d-flex justify-content-center mt-4 "> Do you want to Acknowledge? </div>
                                            :
                                            <div className="d-flex justify-content-center mt-4 "> Do you want to Reject? </div>
                                }

                            </div>

                            <div className="col-12 col-lg-12">

                                <div className="btn-action d-flex justify-content-center mt-4 " >
                                    <button
                                        disabled={isARLoading}
                                        type="button"
                                        className="btn btn-success mx-3"
                                        style={{ width: '120px' }}
                                        onClick={() => handleApproved()}
                                    >
                                        {isARLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {!isARLoading && <span> <i class="fa-solid fa-check pe-1"></i>
                                            {heading == 'Approval PopUp' ? 'Accept' : heading == "Reject PopUp" ? "Reject" : "Yes"}
                                        </span>}

                                    </button>
                                    <button type="button"
                                        className="btn btn-danger mx-3"
                                        style={{ width: '100px' }}
                                        onClick={handleAddCloseClick}
                                    > <i class="fa-solid fa-xmark pe-1"></i> No</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}