import { useEffect, useRef, useState } from "react"
import Footer from "../../../../Components/Footer/Footer"
import Header from "../../../../Components/Header/Header"
import { useNavigate } from "react-router-dom"
import { loginUser, logout, useAuthDispatch, useAuthState } from "../../../../Helper/Context"
import ReCAPTCHA from "react-google-recaptcha"
import { REACT_APP_SECRET_KEY, REACT_APP_SITE_KEY } from "../../../../Helper/Captcha"
import { RegistrationPopUp } from "../../../PopUp/RegistrationPopUp"


export const LoginPage = () => {

    // const [UserName, setUserName] = useState('');
    const navigate = useNavigate();
    const dispatch = useAuthDispatch()
    const userDetails = useAuthState()
    const { RoleID } = userDetails
    // console.log("userDetails", userDetails)
    const captchaRef = useRef(null)
    const [passwordField, setpasswordField] = useState("password")
    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const [Validationerror, setValidationerror] = useState("")
    const [loading, setloading] = useState(false)

    const [Loginmessage, setLoginmessage] = useState('')
    const [Captchaloading, setCaptchaloading] = useState(false)
    const [IsCaptchaVerify, setIsCaptchaVerify] = useState(false)

    useEffect(() => {
        logout(dispatch)
        // showPassEncrypt()
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault()
        if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard });
                if (!response) return;
                // navigate('/dashboard');

            } catch (error) {
                console.log(error);
            }
            setValidationerror("")
        } else {
            setValidationerror("Error")
        }

    }

    const handleRollIdWiseDashboard = (data) => {
        setLoginmessage(data.error)
        if (data.RoleID == 1) {
            navigate(`/admin?username=admin`)
        } else {
            navigate('/knowledgeRepositoryReport');
        }
    }
    const handleErrorIdWiseDashboard = (data) => {
        setLoginmessage(data.error)
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye-slash") {
            y.className = "fa fa-eye";
        } else {
            y.className = "fa fa-eye-slash";
        }
    }


    const showPassEncrypt = () => {
        var x = document.getElementById("pass");
        x.type = "password";
    }

    const onChangeCaptcha = (value, e) => {
        const token = captchaRef.current.getValue();
        if (value) {
            VerifyCaptcha(value)
        } else {
            alert.show('Something Went Wrong')
        }
    }

    const VerifyCaptcha = (Token) => {
        setCaptchaloading(true)
        var formdata = new FormData();
        formdata.append("secret", REACT_APP_SECRET_KEY);
        formdata.append("response", Token);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://www.google.com/recaptcha/api/siteverify", requestOptions)
            .then(response => response.json())
            .then(result => {
                setIsCaptchaVerify(true)
                if (result.success) {
                    setIsCaptchaVerify(result.success)
                } else {
                    // alert.show('timeout-or-duplicate')
                }
                setCaptchaloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setIsCaptchaVerify(true)

                setCaptchaloading(false)
            });
    }

    const [showRegistration, setshowRegistration] = useState(false)

    const handleShowRegistration = () => {
        setshowRegistration(!showRegistration)
    }

    return (
        <div className="container-fluid g-0">

            <Header />

            {/* <!-- login Form --> */}

            <div className="row login_page px-3 px-lg-5 py-3 ">
                <div className=" col-lg-8">

                </div>

                <div className=" col-12 col-lg-4 py-3 px-4 px-lg-0 bg-white rounded">
                    <div className="bg-white  pt-md-2  " >
                        <div className="px-lg-5 pt-2">
                            <h4 className="pb-1 lightgreencolor fw-bold text-center">Login </h4>
                            <h4 className="pb-4 greendarkcolor fw-bold fs-5 text-center" >Global Green Credit Initiative </h4>
                            <form>

                                <div className="mb-3">
                                    <label for="exampleInputEmail1"
                                        className="form-label lightgreencolor fw-bold">Username</label>
                                    <div className="input-group bg-white ">
                                        <span className="input-group-text  bg-white login_input_icon rounded-0 cursor-pointer" >
                                            <span id='user' className="fa-solid fa-user"> </span>
                                        </span>
                                        <input
                                            type="text" className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            onChange={(e) => setuserName(e.target.value)} />
                                    </div>
                                    {/* <!-- <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> --> */}
                                </div>

                                <div className="mb-3">
                                    <label for="exampleInputPassword1"
                                        className="form-label lightgreencolor fw-bold">Password
                                    </label>
                                    {/* <input type="password" className="form-control" id="exampleInputPassword1"
                                        onChange={(e) => setpassword(e.target.value)}
                                    /> */}
                                    <div className="input-group ">
                                        <span className="input-group-text bg-white login_input_icon rounded-0 cursor-pointer">
                                            <span id='lock' className="fa-solid fa-lock"> </span>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="password"
                                            id="pass"
                                            autoSave="none"
                                            autoComplete="new-password"
                                            // value={FormData.ConfirmPassword}
                                            onChange={(e) => {
                                                setpassword(e.target.value)
                                                showPassEncrypt()
                                            }} />
                                        <span className="input-group-text bg-white rounded-0 cursor-pointer"
                                            onClick={() => showPass()} >
                                            <span id='eye' className="fa fa-eye-slash"> </span>
                                        </span>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    sitekey={REACT_APP_SITE_KEY}
                                    ref={captchaRef}
                                    type='image'
                                    onChange={onChangeCaptcha}
                                />

                                <div className="mb-3 mt-2 fs-7">
                                    <a className="text-primary cursor-pointer"
                                        onClick={() => navigate('/forgotPassword')}
                                    > Forgot Password ?</a>
                                </div>

                                <button className="lightgreenbg all_button w-100"
                                    style={{ cursor: (!IsCaptchaVerify || (userName == '' && password == '')) ? 'not-allowed' : 'pointer' }}
                                    // style={{ cursor: ((userName == '' && password == '')) ? 'not-allowed' : 'pointer' }}
                                    disabled={
                                        !IsCaptchaVerify ||
                                        loading ||
                                        userName == '' ||
                                        password == ''
                                    }
                                    onClick={handleLogin}
                                >{loading && (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                    {!loading && <span>Login</span>}

                                </button>
                                <div className="my-3 fs-7 ">
                                    <a className="lightgreencolor"> {Loginmessage}</a>
                                </div>

                                <div className="mb-3 mt-2 fs-7 text-center">
                                    <a className="text-primary "
                                    > Don’t have an account? <span onClick={handleShowRegistration} className="cursor-pointer fw-bold text-decoration-underline">Register</span>
                                    </a>
                                </div>

                                {/* <div className="mb-3 mt-2 fs-7">
                                    <a className="text-primary cursor-pointer"
                                        onClick={handleShowRegistration}
                                    > Registration link</a>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {
                showRegistration &&
                <RegistrationPopUp
                    handleShowRegistration={handleShowRegistration}
                />
            }
        </div>


    )
}