import React from 'react'
import Header from '../../../../Components/Header/Header'
import Footer from '../../../../Components/Footer/Footer'
import { HeroSection } from './HeroSection/HeroSection'
import { HomeAboutUS } from './HomeAboutUS/HomeAboutUS'
import { HomeKnowledge } from './HomeKnowledge/HomeKnowledge'
import { HomeReports } from './HomeReports/HomeReports'
import { HomeCaseStudy } from './HomeCaseStudy/HomeCaseStudy'
import { HomeMultimedia } from './HomeMultimedia/HomeMultimedia'
import { HomeJoinPledge } from './HomeJoinPledge/HomeJoinPledge'
import { HomeUpcomingEvents } from './HomeUpcomingEvents/HomeUpcomingEvents'
import { HomePastEvent } from './HomePastEvent/HomePastEvent'
import { QueryForm } from '../../QueryForm/QueryForm'
import { HomeEngage } from './HomeEngage/HomeEngage'
import { HomeOurPartner } from './HomeOurPartner/HomeOurPartner'
import { HomePartnerMap } from './HomePartnerMap/HomePartnerMap'
import HomeReportCaseStudy from './HomeReportCaseStudy/HomeReportCaseStudy'
import HomeGPlanet from './HomeGPlanetSection/HomeGPlanet'
import HomeGCP from './HomeGCPSection/HomeGCP'

const Home = () => {

    return (
        <>
            <Header active='Home' />

            <HeroSection />

            <HomeAboutUS />

            <HomeGPlanet />

            <HomeGCP />

            {/* <HomeJoinPledge /> */}

            <HomeKnowledge />

            {/* <HomeReports />
            <HomeReportCaseStudy /> */}

            <HomePartnerMap />

            {/* <HomeMultimedia /> */}

            {/* <HomeUpcomingEvents /> */}

            {/* <HomePastEvent /> */}

            <QueryForm />

            <HomeEngage />

            {/* <HomeOurPartner /> */}

            <Footer />
        </>
    )
}

export default Home